<template>


<div>

  <b-row v-if="loading">
    

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6"></b-col>

                <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <b-skeleton width="100%"  height="40px"></b-skeleton>

                </b-col>

                <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <b-skeleton width="100%"  height="40px"></b-skeleton>

                </b-col>

                <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>


                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="40px"></b-skeleton>

                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="40px"></b-skeleton>

                  </b-col>


                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  
                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <b-skeleton width="100%"  height="40px"></b-skeleton>

                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  
                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <b-skeleton width="100%"  height="40px"></b-skeleton>

                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  
                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <b-skeleton width="100%"  height="40px"></b-skeleton>

                  </b-col>


                  <b-col cols="8" sm="8" md="8" lg="8" xl="8">
              
                  </b-col>

                  <b-col cols="4" sm="4" md="4" lg="4" xl="4">
                    <b-skeleton width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>

                  </b-col>

    
        </b-row>
        <b-row v-else>

          <b-col v-if="isFree" cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px" align="center">

            <img src="/img/error.svg" style="width: 100px;margin-bottom:10px"/>

            
            <b-alert
                          variant="primary"
                          show
                        >
                          <div class="alert-body">
                            <p  style=" font-size:13px;">
                          Lo sentimos, este usuario no puede recibir pagos con tarjetas. Por favor escoja otra forma de pago.
                         </p>
                          </div>
                        </b-alert>


                         

            </b-col>
          <b-col v-else-if="firstStep" cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">
                        <b-form autocomplete="off" @submit.prevent="onSubmitFirstStep"  >

                          <b-row>

                            <b-col
                              cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                                xl="12"
                              >

                              <p class="textLabel">Seleccione su nacionalidad:</p>


                              </b-col>
                              <b-col
                              cols="6"
                                sm="6"
                                md="6"
                                lg="6"
                                xl="6"
                              >

                            <div class="inputGroup">



                            <input id="Ecuatoriano" name="radio" @change="onChange($event)" :checked="checked"  value="Ecuatoriano" type="radio"/>
                            <label for="Ecuatoriano"> 

                              <span> <img  alt="Ecuador" style="margin-right: 5px;width: 25px;" src="/img/ecuador.svg">  </span>Ecuatoriano</label>
                            </div>

                            </b-col>

                            <b-col
                              cols="6"
                                sm="6"
                                md="6"
                                lg="6"
                                xl="6"
                                style="margin-bottom:10px"
                              >

                            <div class="inputGroup">



                            <input id="Extranjero" name="radio" @change="onChange($event)"  value="Extranjero" type="radio"/>
                            <label for="Extranjero"> 

                              <span> <img  alt="Extranjero" style="margin-right: 5px;width: 25px;" src="/img/world.svg">  </span>Extranjero</label>
                            </div>

                            </b-col>

                 
                                        
                            <b-col
                            cols="12"
                              sm="12"
                              md="5"
                              lg="5"
                              xl="5"
                              style="margin-bottom: 10px;"
                            >

                              <p  v-if="isEcuatoriano" class="textLabel">
                                Cédula (*):
                              </p>

                              <p  v-else class="textLabel">
                                DNI (*):
                              </p>

                              <b-form-input
                                v-if="isEcuatoriano"
                                id="dni"
                                v-model="dni"
                                placeholder="Ingrese su cédula"
                                required
                                @keypress="NumbersOnly"
                                type="tel"
                                :readonly="activarCampo"
                                @blur="checkDNI"
  
                              />


                              <b-form-input
                              v-else
                                id="dni"
                                v-model="dni"
                                placeholder="Ingrese su DNI"
                                required
                                type="text"
                                :readonly="activarCampo"
                           
                              />

                            </b-col>

                            <b-col
                            cols="12"
                              sm="12"
                              md="7"
                              lg="7"
                              xl="7"
                              style="margin-bottom: 10px;"
                            >

                              <p class="textLabel">
                                Nombres y apellidos:
                              </p>

        

                                <b-form-input
                                id="displayName"
                                v-model="displayName"
                                placeholder="Ingrese su apellido y nombre"
                                required
                                type="text"
                                :readonly="isEcuatoriano"
                                @keypress="isLetter"
                              />

                            </b-col>


                            <b-col
                            cols="12"
                              sm="12"
                              md="12"
                              lg="12"
                              xl="12"
                            style="margin-bottom: 10px;"
                          >

                            <p
                              class="textLabel"
                            >
                              Celular (*):
                            </p>

                            <MazPhoneNumberInput
                                v-model="phoneNumber"
                                type="tel"
                                default-country-code="EC"
                                no-flags
                                no-example
                                required
                                show-code-on-list
                                size="md"
                                :translations="{
                                  countrySelectorLabel: 'Codigo',
                                  countrySelectorError: 'Seleccione un pais',
                                  phoneNumberLabel: 'Ingrese su número celular',
                                }"
                                @update="results = $event"
                              />

                         

                          </b-col>

                          
                          <b-col
                            cols="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            style="margin-bottom: 10px;"
                          >

                            <p
                              class="textLabel"
                            >
                              Dirección (*):
                            </p>

                            <b-form-input
                              v-model="direccion"
                              type="text"
                              required
                              placeholder="Ingrese una dirección"
                            />

                          </b-col>

                            <b-col
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            style="margin-bottom: 15px;"
                          >

                            <p
                              class="textLabel"
                            >
                              Email (*):
                            </p>

                            <b-form-input
                              v-model="email"
                              type="email"
                              required
                              placeholder="Ingrese un email"
                            />

                          </b-col>

                            
                        <b-col sm="12" md="12" lg="12" xl="12" style="    text-align: right;" >

                          <b-button id="btn_siguiente" :disabled="isComprobando"   class="animacion_button"  type="submit" style="border-radius:50px"  variant="primary" >  Siguiente        
                           
                            <svg width="12" fill="#fff" height="12" style="margin-left: 15px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 240.823 240.823"><g><path id="Chevron_Right_1_" d="M183.189 111.816 74.892 3.555c-4.752-4.74-12.451-4.74-17.215.0-4.752 4.74-4.752 12.439.0 17.179l99.707 99.671-99.695 99.671c-4.752 4.74-4.752 12.439.0 17.191 4.752 4.74 12.463 4.74 17.215.0l108.297-108.261C187.881 124.315 187.881 116.495 183.189 111.816z"/><g/><g/><g/><g/><g/><g/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                          
                          </b-button>



                          </b-col>


                          </b-row>



                          </b-form>
        </b-col>
        <b-col v-else cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">
                <b-form autocomplete="off"  >
         
                    <b-row>
                      <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style="margin-bottom:5px"
                        >

                    
                                  <p
                            class="textLabel"
                            style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
                          >
                            Monto en dólares a enviar (*): <feather-icon
                                              icon="HelpCircleIcon"
                                              size="16"
                                                v-b-popover.hover.bottom.html="'Es la cantidad en dólares (USD) a pagarle al propietario de este link.'"
                                              title="Monto de envio"
                                              style="margin-right: 5px;cursor:pointer;"
                                              /> </p>

                              <AutoNumericVue
                                  v-model="mountTarjeta"
                                  required
                                 
                                  type="text" inputmode="decimal"
                                  :readonly="isDisabled"
                                  @input="onInput"
                                  class="autonumeric_input"
                                  :options="json_opciones"
                                  :placeholder="'$0.00'"
                              ></AutoNumericVue>

                              <p style="margin-bottom:5px; text-align: center;font-size:11px; font-style:italic">El valor ingresado debe ser en dólares americanos.</p>

                        </b-col>

                        <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style="margin-bottom: 15px;"
                          v-if="!hasPlanElite"
                         >

                        <b-alert
                          variant="secondary"
                          show
                        >
                          <div class="alert-body">
                            <p style="text-align: center;margin:0px">Este usuario admite pagos de hasta <b>${{maxTransaction}} USD </b> por transacción </p>
                          </div>
                        </b-alert>

                        </b-col>

                        <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                        >
                          <p class="textLabel">
                            Datos de la tarjeta (*):
                          </p>
                          <inline-credit-card-field
                            v-model="card"
                            :disabled="isDisabled"

                            required
                            @invalid="resultsInvalid = $event"
                            @valid="resultsCard = $event"
                          />

                        </b-col>

                     


                        <b-col cols="6" sm="6" md="6" lg="6" xl="6" align="center" style="margin-top:15px">
                        
                            <b-button block
                            :disabled="isActive2"
                                                      @click="atras"
                                                      variant="outline-secondary"
                                                      style="margin-right: 15px;border-radius:50px;"
                                                    >
                                                    <feather-icon

                                                        icon="ArrowLeftCircleIcon"
                                                        size="13"
                                                        style= "margin-right:10px"
                                                        />
                                                      Atras 
    
                                                    </b-button>

                           </b-col>
                           <b-col cols="6" sm="6" md="6" lg="6" xl="6" align="center" style="margin-top:15px">
                                                

                        <b-button block
                                                      id="btn_paytarjeta"
                                                      ref="btn_paytarjeta"
             
                                                      variant="primary"
                                                      class="animacion_button"
                                                      :disabled="isActive2"
                                                      @click="verifyPay"
                                                      style="border-radius:50px;justify-content: space-between;display: flex"
                                                    >
    
                                                    <span style="font-size: 16px;">Pagar </span> 
                                                     <span style="font-size: 16px;">${{mountFormated}}</span> 
    
                                                    </b-button>

                     </b-col>

                     <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                          style="margin-top: 10px;"
                        >

                          <img
                            alt="PCI"
                            style="margin-right: 10px;"
                            src="/img/pci.svg"
                          >
                          <img
                            alt="VISA"
                            style="margin-right: 10px;"
                            src="/img/visa.svg"
                          >
                          <img
                            alt="Mastercard"
                            style="margin-right: 10px;"
                            src="/img/mastercard.svg"
                          >
                          <img
                            alt="American Express"
                            style="margin-right: 10px;"
                            src="/img/american-express.svg"
                          >

                          <p
                            style="font-size: 10px; line-height: 12px;margin-bottom: 0px;"
                          >Los datos de este pago son encriptados y manejados por nuestro sistema de seguridad antifraude. Norma de seguridad PCI-DSS nivel 3.</p>

                        </b-col>

                     


                    </b-row>

                    </b-form>

</b-col>
                  </b-row>         

             

    </div>
      </template>
      
      <script>
      
          /* eslint-disable global-require */
      import {  BFormInput, BRow, BCol, BButton, BSkeleton, BAlert,BCard, BForm,VBPopover,VBTooltip, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormGroup, } from 'bootstrap-vue'
      import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
      import InlineCreditCardField from '../profile/InlineCreditCardField.vue'
  
      export default {
          components: {
          BRow,
          BCol,
          BCard,
          BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormGroup,
          BSkeleton,
          BButton,
          BFormInput,
          BAlert, BForm,
          AutoNumericVue,
          InlineCreditCardField,
          },
          directives: {
          'b-popover': VBPopover,
          'b-tooltip': VBTooltip,
        },
        props: ['alias' , 'tokenAuth'],
          data() {



      
          
          return {

              loading:true,
              mountFormated:"0.00",
              isDisabled:false,
              json_opciones:{},
              intentos:0,
              idPay:"",
              dni:"",
              email:"",
              title:"",
              direccion:"",
              isActive2:false,
              mountVerify:"",
              activarCampo:false,
              firstStep:true,
              showVerify:true,
              checked: true,
              isEcuatoriano:true,
              displayName:"",
              phoneNumber: null,
              results: null,
              isCardValid:false,
              isComprobando:false,
              isPhoneValid:false,
              mountTarjeta:"",
              minPayLink:0,
              resultsInvalid: null,
              resultsCard: null,
              cardNumber: '',
              expMonth: '',
              cvc: '',
              expYear: '',
              linkVerify:"",
              brand: '',
              card:"",
              dniRespaldo:"",
              nacionalidad:"Ecuatoriano",
              maxTransaction:0,
              hasPlanElite:"",
              isFree:false

          }
          },
          created() {
      
          },
          watch: {


            results(newValue) {
           this.isPhoneValid= newValue.isValid;


            },   resultsCard(newValue) {

        
this.cardNumber = newValue.card.number
this.expMonth = newValue.card.expMonth
this.cvc = newValue.card.cvc
this.expYear = newValue.card.expYear
this.brand = newValue.card.brand




if (this.cardNumber?.toString().length >= Number('14') && this.expMonth?.toString().length == Number('2') && this.cvc?.toString().length >= Number('3') && this.expYear?.toString().length == Number('2')) {
  this.isCardValid = true
} else {
  this.isCardValid = false
}
},

resultsInvalid() {
this.isCardValid = false
},
      
              
      
              },
          async mounted() {
    
    
    
          

    

          this.cargarScript();
 

 


        this.getComision();

       
      
          },
          methods: {

            cargarScript(){

                   // As an instance method inside a component
                this.$loadScript("https://js.stripe.com/v3/")
                  .then(() => {
                    // Script is loaded, do something
                   
                  })
                  .catch(() => {
                    // Failed to fetch script
                   this.cargarScript();
                  });

            },


            getComision(){

              
                  this.loading=true;
                    const encripted = this.$encryptBackoffice.encrypt(this.alias)

                this.$https.post('/links/getComisionPayTarjetaByAlias/', {  alias: encripted }).then(response => {
                    

                  
                  if (response.data.code == 200) {
                    this.loading = false;

                    if(response.data.isFree){
                      this.isFree=true;
                    }else{

                      this.isFree=false;
                      this.hasPlanElite= response.data.hasPlanElite;
                      this.maxTransaction= response.data.maxTransaction;
                      this.minPayLink= response.data.minPayLink;

                      this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:this.maxTransaction, modifyValueOnWheel: false };
           

                    }
                
             

                  } else {

                    
                    this.getComision();

                  
                  }
                }).catch(error => {
                    this.getComision();
                })

            },

            onCopyLink() {
          this.$toast.success('Link copiado exitosamente', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },
        onErrorLink() {
          this.$toast.error('Falló en copiar el link', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },

            onBlurMount(){

                    
                  

              if(this.mountVerify == null || this.mountVerify == "null" || this.mountVerify ==""){

                if(Number(this.mountVerify) == Number("0")){

                      this.mountVerify="";

   


                      }



              }
              },

         
            onInput(value){


              if(value === null  ){

                  
                  

                      this.mountFormated="0.00";




                  }else{

                    if(Number.isInteger(value)){

                      this.mountFormated=value+".00";

                    }else{
                      this.mountFormated=value;
                    }

                      

                      


                  }

              },
              isNumberBlur(){


                if(this.dni === null || this.dni === ""){

                  this.dni="";


                  }else{


                    if(isNaN(this.dni)){

                      this.$toast.error("El campo DNI solo permite valores numéricos", {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })
                        this.dni="";

                    }



                  }



                },
               NumbersOnly(evt) {
                  evt = (evt) || window.event
                  const charCode = (evt.which) ? evt.which : evt.keyCode
                  if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                    evt.preventDefault()
                  } else {
                    return true
                  }
                },

                          isLetter(e) {
                    const char = String.fromCharCode(e.keyCode) // Get the character
                    if (/^[a-zA-Z\s]*$/g.test(char)) return true // Match with regex
                    e.preventDefault() // If not match, don't add to input text
                  },

                          atras(){
                              this.firstStep=true;
                          },


                        checkDNI() {
                          if (this.dni == '') {

                            this.displayName="";
                            this.dniRespaldo="";

                          } else if (this.dni != this.dniRespaldo) {

                            


                          
                          document.getElementById('btn_siguiente').innerHTML = '<img style="margin-left:10px; width:15px " src="/img/loading2.svg" alt="Loading" />  Comprobando cédula, espere'

                          this.isComprobando=true;

                            const dataEncripted = this.$encryptBackoffice.encrypt(this.dni)
                        
                      this.$https.post('/profile/consultaDatos/', {  cedula: dataEncripted }).then(response => {

                      
                        document.getElementById('btn_siguiente').innerHTML = 'Siguiente  <svg width="12" fill="#fff" height="12" style="margin-left: 15px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 240.823 240.823"><g><path id="Chevron_Right_1_" d="M183.189 111.816 74.892 3.555c-4.752-4.74-12.451-4.74-17.215.0-4.752 4.74-4.752 12.439.0 17.179l99.707 99.671-99.695 99.671c-4.752 4.74-4.752 12.439.0 17.191 4.752 4.74 12.463 4.74 17.215.0l108.297-108.261C187.881 124.315 187.881 116.495 183.189 111.816z"/><g/><g/><g/><g/><g/><g/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>'
                        this.isComprobando=false;
                              if (response.data.code == 200) {

                            
                          

                                this.displayName = response.data.dataGeneral.nombres.trim()
                                this.dniRespaldo = this.dni
                              
                              } else {
                                this.dni = ''
                                this.dniRespaldo="";
                          
                                this.displayName = ''
                                this.$toast.error(response.data.message, {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                                })
                              }
                            }).catch(error => {
                              this.dni = ''
                              
                              this.displayName = ''
                              this.$toast.error("Ocurrió un error inesperado", {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                              })
                            })
                          }
                        },


                  
                        onChange(event) {

                
                    
                            this.dni="";
                            this.displayName="";
                      
                                if(event.target.value === "Ecuatoriano"){
                                  this.isEcuatoriano=true;
                                  this.nacionalidad= "Ecuatoriano";
                                  
                                  
                                  

                                }else{

                                    this.isEcuatoriano=false;
                                    this.nacionalidad= "Extranjero";
                                }
                              },

                          

                          onSubmitFirstStep(event){
                                event.preventDefault()

                                if(this.isPhoneValid){
                                  this.firstStep=false;
                                }else{

                                  this.phoneNumber="";

                                  
                                  this.$toast.error("El número celular ingresado no es válido", {
                                      position: 'top-right',
                                      timeout: 3010,
                                      closeOnClick: true,
                                      pauseOnFocusLoss: false,
                                      pauseOnHover: true,
                                      draggable: true,
                                      draggablePercent: 0.4,
                                      showCloseButtonOnHover: false,
                                      hideProgressBar: true,
                                      closeButton: 'button',
                                      icon: true,
                                      rtl: false,
                                      })

                                }
                            


                              },

                              verifyPay(){
                                if(Number(this.mountTarjeta) >= Number(this.minPayLink)){
                                if(this.isCardValid){

                                    this.mount= Number(this.mountTarjeta).toFixed(2);

                                    const user_json = {
                                        displayName: this.displayName,dni:this.dni, alias: this.alias, nacionalidad: this.nacionalidad, phone: this.phoneNumber, email: this.email, direccion: this.direccion
                                      }


                                      const pay_json = {
                                        cvc: this.cvc,expYear:this.expYear, brand: this.brand, mount: this.mount,cardNumber:this.cardNumber, expMonth: this.expMonth 
                                      }




                                    const user_string = JSON.stringify(user_json)
                                    const pay_string = JSON.stringify(pay_json)



                                    const dataEncripted1 = this.$encryptBackoffice.encrypt(user_string)
                                    const dataEncripted2 = this.$encryptBackoffice.encrypt(pay_string)

                                    this.isActive2 = true
                                        document.getElementById('btn_paytarjeta').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Realizando pago'

                                

                                    
                                        this.$https.post('/links/submitBilleteraPay/', { tokenAuth: this.tokenAuth, userData: dataEncripted1, payData: dataEncripted2}).then(response => {
                                      
                                        this.isActive2 = false
                        
                                        
                                        
                        
                                        document.getElementById('btn_paytarjeta').innerHTML = ' <span style="font-size: 16px;">Pagar </span>  <span style="font-size: 16px;">$'+this.mount+'</span> ';
                                    
                                  
                                        if (response.data.code == 200) {

                                          this.$toast.success(response.data.message, {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: false,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                            });

                                            this.$eventBus.$emit('reiniciarPayTarjetas');


                                        
                                            
                                            
                        
                                        } else {
                                          
                        
                                            this.$toast.error(response.data.message, {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: false,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                            })

                                            if (response.data.code == 401) {
                        
                                                  localStorage.removeItem('userData')


                              
                                                  this.$eventBus.$emit('reiniciarWesterForm')
                                                    this.$eventBus.$emit('reiniciarAllFormsPay');
                              
                                                  } 
                        
                                         
                                        }
                                        }).catch(error => {

                                      
                        
                                          this.isActive2 = false
                                        this.$toast.error("Ocurrió un error inesperado", {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: false,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                        })
                                        })
                        

                                  

                                }else{

                                  this.$toast.error("Los datos de tu tarjeta están incompletas o erróneas", {
                                      position: 'top-right',
                                      timeout: 3010,
                                      closeOnClick: true,
                                      pauseOnFocusLoss: false,
                                      pauseOnHover: true,
                                      draggable: true,
                                      draggablePercent: 0.4,
                                      showCloseButtonOnHover: false,
                                      hideProgressBar: true,
                                      closeButton: 'button',
                                      icon: true,
                                      rtl: false,
                                      })

                                }

                              }else{


                                this.$toast.error("Debe ingresar un valor mayor a $"+this.minPayLink+" USD", {
                                    position: 'top-right',
                                    timeout: 3010,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 0.4,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: true,
                                    closeButton: 'button',
                                    icon: true,
                                    rtl: false,
                                    })

                                }

                              


                              },
                        
                       
                  
                            
    
         
       
      
          },
      }
      </script>
      
      <style lang="scss" >
      
   
      </style>
      
          