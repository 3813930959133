<template>


    <div>
    
        <b-row v-if="isLogeo">

          <b-modal
            ref="modalPayCriptoSuccess"
            centered
            hide-footer
            title="Pago realizado con éxito"
            @hidden="closeModalPayCripto"
          >
            <div class="d-block text-center">

              <b-row>

                <b-col
                  cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    align="center"
                    style="margin-bottom: 10px;"
                  >

                  <img  src="/img/check.svg" alt="Pago exitoso" />

                  </b-col>

                          <b-col
                    cols="5"
                    sm="5"
                    md="5"
                    lg="5"
                    xl="5"
                  >

                    <div class="profile-image p-0">
                        <b-avatar
                          size="50"
                          variant="light"
                          :src="photoUser"
                        />
                      </div>


                    <h6 :title="scanData" class="text-muted" style="cursor: default;margin-top: 5px;font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                      {{ scanData }}
                    </h6>

                  </b-col>

                    <b-col
                    cols="2"
                    sm="2"
                    md="2"
                    lg="2"
                    xl="2"
                    style="display: flex;"
                  >

                  <svg width="30" height="30"  class="flecha" viewBox="-32 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9.0L441 239c9.4 9.4 9.4 24.6.0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9.0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3.0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/></svg>

                  </b-col>

                  <b-col
                    cols="5"
                    sm="5"
                    md="5"
                    lg="5"
                    xl="5"
                  >

                  <div class="profile-image p-0">
                        <b-avatar
                          size="50"
                          variant="light"
                          :src="photo"
                        />
                      </div>


                    <h6 class="text-muted" style=" margin-top: 5px; font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                      {{ dataScan }}
                    </h6>

                  </b-col>


                  <b-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                  >
                  <p style="text-align: center;font-size: 13px;margin-bottom: 5px;margin-top: 15px;">Enviado a tu contacto</p>

                      <h4 :title="displayName" style="    cursor: default;font-size:16px;margin-bottom: 0px;margin-top: 5px;    text-transform: uppercase; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" class="font-weight-bolder">
                      {{ displayName }}
                    </h4>

                    <p style="text-align: center;font-size: 30px;font-weight: bold;    margin-top: 15px;">${{mount}}</p>

                    <p style="text-align: center;font-size: 12px;margin: 0;">{{date}}</p>

                    <b-alert v-if="hasDescription" variant="primary" style="margin-top: 5px !important; " show>
                    <div class="alert-body">



                          <p style="font-size: 14px; margin-bottom:0;">{{description}}</p>


                    

                    
                    </div>
                  </b-alert>



                  </b-col>

              

                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  align="center"
                  style="margin-top:10px"
                >

                <b-button

                          variant="primary"
                          @click="closeModal"
                          
                        >

                          Entendido

                        </b-button>

                </b-col>


                </b-row>


              </div>


              </b-modal>
    
    
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="loading" style="padding:0px">
    
                <b-row>
    
      
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <b-skeleton width="100%"  height="80px"></b-skeleton>
    
                    </b-col>
    
                
                     <b-col cols="12" sm="12" md="2" lg="2" xl="2"></b-col>
    
                            <b-col cols="12" sm="12" md="8" lg="8" xl="8">
                                <b-skeleton width="100%"  height="10px"></b-skeleton>
    
                            </b-col>
    
                            <b-col cols="12" sm="12" md="2" lg="2" xl="2"></b-col>
    
                            <b-col cols="12" sm="12" md="3" lg="3" xl="3"></b-col>
    
                            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <b-skeleton width="100%"  height="55px"></b-skeleton>
    
                            </b-col>
    
                            <b-col cols="12" sm="12" md="3" lg="3" xl="3"></b-col>
    
                            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <b-skeleton width="70%"  height="10px"></b-skeleton>
    
                            </b-col>
    
                            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <b-skeleton width="100%"  height="70px"></b-skeleton>
    
                            </b-col>
    
                            <b-col
                                    cols="2"
                                        sm="2"
                                        md="2"
                                        lg="2"
                                        xl="2"
                                    > </b-col>
    
    
                                    <b-col
                                    cols="8"
                                        sm="8"
                                        md="8"
                                        lg="8"
                                        xl="8"
                                    > 
    
                                    <b-skeleton width="100%"  height="10px"></b-skeleton>
    
                                    <table width="100%">
                                        <tr>
                                        <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                        <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                        <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                        <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                        <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                        <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                        </tr>
                                    </table>
    
                                    
                                    </b-col>
    
                                    <b-col
                                    cols="2"
                                        sm="2"
                                        md="2"
                                        lg="2"
                                        xl="2"
                                    >   </b-col>
    
    
                                    <b-col
                                    cols="4"
                                        sm="4"
                                        md="4"
                                        lg="4"
                                        xl="4"
                                    > </b-col>
    
    
                                    <b-col
                                    cols="4"
                                        sm="4"
                                        md="4"
                                        lg="4"
                                        xl="4"
                                    >
    
                                    <b-skeleton width="100%"  height="40px"></b-skeleton>
                                    
                                    </b-col>
    
    
                                    <b-col
                                    cols="4"
                                        sm="4"
                                        md="4"
                                        lg="4"
                                        xl="4"
                                    > </b-col>
    
    
                </b-row>
    
    
    
            </b-col>

            <b-form
                autocomplete="off"
                @submit.prevent="onSubmitSendCripto"
                v-if="showFormCripto"
              >

                  <b-row>

                      <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                    >

                    <b-alert
                        variant="secondary"
                        show
                        style="margin-bottom: 10px !important;padding: 5px;"
                      >
                        <div class="alert-body">
                          
                                <p style="text-align: center;font-weight: bold;">Saldo de billetera</p>

                            <p class="saldoDisponible"><span style="font-size:25px">$</span>{{saldoDisponible }}<small style="font-weight: bold; font-size:10px"> USD</small></p>

                        <p style="text-align: center; font-size: 15px;">= {{balanceCripto}}<small style="font-weight: bold; font-size:10px"> {{currency}}</small></p>
                  

                        
                        </div>
                      </b-alert>


                      </b-col>

                    
              <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom:5px"
            >

        
                      <p
                class="textLabel"
                style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
              >
                Monto en dólares a transferir (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'El monto en dólares (USD) a enviarse en valor de criptomoneda. Este monto debe ser igual o menor a su saldo disponible.'"
                                  title="Monto de transferencia"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

                  <AutoNumericVue
                      v-model="mountSend"
                      required
                      type="text" inputmode="decimal"
                      :readonly="isDisabled"
                      @input="handleInput2"
                      @blur="handleInput"
                      class="autonumeric_input"
                      :options="json_opciones"
                      :placeholder="'$0.00'"
                  ></AutoNumericVue>


            </b-col>



            <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
              <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                Descripción de la transferencia (Opcional):</p>

                 <b-form-textarea
                v-model="description"
                placeholder="Ingrese una descripción corta de la transferencia (Opcional)"
                rows="2"
                no-resize
                :readonly="isDisabled"
                :state="description.length <= maxChar"
                class="char-textarea"
                :class="description.length >= maxChar ? 'text-danger' : ''"
              />
              <small
                class="textarea-counter-value float-left"
                :class="description.length >= maxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ description.length }}</span> / {{ maxChar }}
              </small>

          </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

           

              <p
                class="textLabel"
                style="text-align: center;margin-top: 0px;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

              <PincodeInput
                v-model="pin"
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                :disabled="isDisabled"
               
                required
              />

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              align="center"
              style="margin-bottom:10px"
            >

              <b-button
                id="btn_sendCripto"
                ref="btn_sendCripto"
                type="submit"
                variant="primary"
                :disabled="isDisabled"
                block
                style="border-radius:50px;justify-content: space-between;display: flex"
              >
              <span style="font-size: 16px;"> Transferir {{currency}}</span> 
               
                <span style="font-size: 16px;">{{mountTransferencia}}</span> 

              </b-button>

            </b-col>


              </b-row>
              </b-form>


              <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="showEmptyCripto" align="center">

                <img style="width:100px;" src="/img/sad.svg" />

                <p style="margin-top:10px; font-size:14px; ">No puedes realizar el pago por este medio debido a que no tienes creada una billetera para esta criptomoneda.</p>

              </b-col>

              <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="showSameWallet" align="center">

                <img style="width:100px;" src="/img/caution.svg" />

                <p style="margin-top:10px; font-size:14px; ">No puedes enviarte saldo de criptomonedas a tus propias billeteras.</p>

                </b-col>


                <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="showTrader" align="center">

                  <img style="width:100px;" src="/img/caution.svg" />

                  <p style="margin-top:10px; font-size:14px; ">No puedes realizar este pago debido a que tienes un rol de Trader.</p>

                  </b-col>
    

    
        </b-row>
    
    
        <b-row v-else >
    
    
    
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="padding:0px">
              <feather-icon
                        icon="ThumbsUpIcon"
                        size="70"
                        class="color_icon"
                        />
                <p style="font-size: 15px;    margin-bottom: 0px; margin-top: 10px">Inicie sesión para realizarle un pago a este usuario</p>
    
    
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="margin-top:15px; margin-bottom: 10px;">
                  <b-button
                              variant="primary"
                              @click="login"
                              class="animacion_button"
                            >
    
                            <feather-icon
                                    icon="LogInIcon"
                                    size="15"
                                    style="margin-right: 5px;"
                                    /> Iniciar sesión
    
                            </b-button>
    
                  </b-col>
    
    
        </b-row>
    
    
    
    </div>
      </template>
      
      <script>
      
          /* eslint-disable global-require */
      import {  BRow, BCol,BAvatar, BButton, BSkeleton,BFormTextarea, BAlert, BForm,VBPopover,VBTooltip } from 'bootstrap-vue'
      import PincodeInput from 'vue-pincode-input'
      import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
    
      export default {
          components: {
          BRow,
          BCol,
          BSkeleton,
          BButton,
          BAvatar,
          BFormTextarea,
          PincodeInput,
          BAlert, BForm,
          AutoNumericVue
          },
          props: ['idWallet', 'currency', 'address','isLogeo'],
          directives: {
          'b-popover': VBPopover,
          'b-tooltip': VBTooltip,
        },
          data() {

            let userId= "";
              let tokenAuth="";
              let photoUser="";
              let displayNameUser="";


            if(this.isLogeo){

              const user = localStorage.getItem('userData');
              const datos_json = JSON.parse(user);

              tokenAuth = datos_json.tokenAuth;

              userId=  datos_json.userId;
                  
                  photoUser= datos_json.photoUser;
                  displayNameUser=  datos_json.displayName;

                 


            }
      

          
          return {
              userId:userId,
              tokenAuth:tokenAuth,
              loading:true,
              saldoDisponible: 0,
              priceCripto:0,
              showFormCripto:false,
              showEmptyCripto:false,
              showSameWallet:false,
              showTrader:false,
              balanceCripto:0,
              checkStatus:0,
              scanData:"",
              photo:"",
              idWalletUserSend:"",
              dataScan:"",
              displayName:"",
              maxChar: 75,
              mount:"",
              isDisabled:false,
              json_opciones:{},
              description:"",
              pin:"",
              date:"",
              hasDescription:false,
              photoUser:photoUser,
              displayNameUser:displayNameUser,
              mountSend:null,
                mountTransferencia:"0.0000",
   
                
          }
          },
          created() {
      
          },
          watch: {
      
              
      
              },
          mounted() {
    
            if(this.isLogeo){
    
                this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1000000, modifyValueOnWheel: false };
      
    
                this.searchQRWallets();

                this.$eventBus.$on('clearInterval', () => {
                  clearInterval(this.checkStatus);
                })
    
               
    
    
            }
      
           
          
      
          },
          methods: {
    
            login(){
    
              this.$eventBus.$emit('showModalLogin');
    
            },
         
         
                 handleInput2(value){


                  if(value === "" || value === "null" || value === null  ){


                            
                            

                                this.mountTransferencia="0.0000";

                        


                            }else{






                        
                                    let mounTrans=Number(value)  / Number(this.priceCripto);

                                        if(Number(mounTrans.toString().length) > 9){
                                            this.mountTransferencia= Number(mounTrans.toString().substring(0, 9));
                                        }else{

                                        this.mountTransferencia= mounTrans;

                                        }


                                


                            }

                        },

                        handleInput () {


                        var t = this.mountSend;

                        if(t === null  ){

                        

                                this.mountTransferencia="0.0000";
                            


                            }else{

                        

                        if(Number(this.mountSend) > Number(this.saldoDisponible)){



                            this.$toast.error("El monto $"+this.mountSend+" USD supera su saldo de billetera.", {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                        });

                                this.mountSend ="";
                            this.mountTransferencia="0.0000";
                        

                        }else{

                            
                        



                        
                            let mounTrans=Number(this.mountSend)  / Number(this.priceCripto);

                            if(Number(mounTrans.toString().length) > 9){
                                this.mountTransferencia= Number(mounTrans.toString().substring(0, 9));
                            }else{

                                this.mountTransferencia= mounTrans;

                            }

                            

                        }




                            }


                        },
               onSubmitSendCripto(event){
                event.preventDefault();

                if(Number(this.description.length) > Number(this.maxChar)){

                      this.description="";

                        this.$toast.error("El texto de la descripción sobrepasa el límite permitido de 75 caracteres", {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })

                      }else{

                      this.mountSend= Number(this.mountSend).toFixed(2);

                      if(Number(this.description.length) > Number("0")){
                        this.hasDescription=true;

                      }else{
                        this.hasDescription=false;
                      }

                      const userId_json = {
                        userId: this.userId,idWalletUserReceive:this.idWallet, pin: this.pin, mount: this.mountSend, description: this.description, idWalletUserSend:this.idWalletUserSend, origen: "billetera"
                      }



                      const user_string = JSON.stringify(userId_json)

      

                      const dataEncripted = this.$encryptCyptos.encrypt(user_string)

                      this.isDisabled = true

                      document.getElementById('btn_sendCripto').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Transferiendo'

                      this.$httpsCryptos.post('/main/payWithQR/', { tokenAuth: this.tokenAuth, datos: dataEncripted}).then(response => {
                      this.isDisabled = false



                      document.getElementById('btn_sendCripto').innerHTML = 'Transferir criptomonedas';


                      if (response.data.code == 200) {

                        this.date= response.data.date;
                        this.mount= this.mountSend;

                       
                       this.$refs.modalPayCriptoSuccess.show();

      

      


                      } else {
                        this.isDisabled = false

                        this.$toast.error(response.data.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })

                        if (response.data.code == 401) {
                          
                          localStorage.removeItem('userData')



                          
                          location.reload();
                        } else if (response.data.code == 503) {
                          this.pin = ''
                        } else if(response.data.code == 501){
                                this.$swal({
                                    title: 'Tu perfil debe estar verificado para realizar esta acción',
                                    text: `¿Deseas verificar tu cuenta ahora?`,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Verificar cuenta',
                                    cancelButtonText: 'No',
                                    customClass: {
                                      confirmButton: 'btn btn-primary',
                                      cancelButton: 'btn btn-outline-secondary ml-1',
                                    },
                                    buttonsStyling: false,
                                  }).then(result => {
                                    if (result.value) {

                                      this.$router.push({ name: 'perfil', hash: '#verificacion' })

                                    }
                                  })
                              }
                      }
                      }).catch(error => {
                      this.$toast.error("Ocurrió un error inesperado", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })
                      })

                      }

              },

              closeModalPayCripto(){
                this.$eventBus.$emit('hideCriptos');
              },

            closeModal(){
                this.$refs.modalPayCriptoSuccess.hide();
                                 
                this.$eventBus.$emit('hideCriptos');
                       

              },     
            checkStatusWallets(){

            
            this.checkStatus = setInterval(() => {


              this.$httpsCryptos.post('/main/priceCripto/', { tokenAuth: this.tokenAuth, currency: this.currency}).then(response => {
                  

                if (response.data.code == 200) {


                  this.priceCripto= response.data.balance;



                  if(this.mountSend != null  ){

                    let mounTrans=Number(this.mountSend)  / Number(this.priceCripto);

                      if(Number(mounTrans.toString().length) > 7){
                          this.mountTransferencia= Number(mounTrans.toString().substring(0, 7));
                      }else{

                        this.mountTransferencia= mounTrans;

                    }


                  }

                


                } else {




                  if (response.data.code == 401) {
                    

                    this.$toast.error(response.data.message, {
                                    position: 'top-right',
                                    timeout: 3010,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 0.4,
                                    showCloseButtonOnHover: true,
                                    hideProgressBar: true,
                                    closeButton: 'button',
                                    icon: true,
                                    rtl: false,
                                  })
    

                                
                                localStorage.removeItem('userData')



                                location.reload();
                  }
                }
              }).catch(error => {
                
              })

              
              }, 30000)

            },

          searchQRWallets(){
          this.loading=true;
          
          const data_json = { userId: this.userId, id: this.idWallet }
           
   
          
            this.$httpsCryptos.post('/main/searchQRWallets/', { tokenAuth: this.tokenAuth, datos: data_json}).then(response => {

       
       
                    
                    this.loading=false;
                            if (response.data.code == 200) {
    
                      
                            
                              this.balanceCripto= response.data.balanceCripto;
                              this.priceCripto= response.data.priceCripto;
                              this.saldoDisponible= Number(Number(this.balanceCripto) * Number(this.priceCripto)).toFixed(2);


                              this.scanData=response.data.addressSend;
                              this.photo= response.data.photo;
                              this.idWalletUserSend=response.data.idWallet;
                              this.dataScan=this.address;
                              this.displayName= response.data.displayName;
         
                            
                              this.showFormCripto=true;
                              this.showEmptyCripto=false;
                              this.showSameWallet=false;
                              this.showTrader=false;
                              this.successPay=false;   
                         
                              this.checkStatusWallets();
                            } else {
    
                              this.showFormCripto=false;
                              this.successPay=false;   
    
                              if (response.data.code == 401) {

                                this.$toast.error(response.data.message, {
                                    position: 'top-right',
                                    timeout: 3010,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 0.4,
                                    showCloseButtonOnHover: true,
                                    hideProgressBar: true,
                                    closeButton: 'button',
                                    icon: true,
                                    rtl: false,
                                  })
    

                                
                                localStorage.removeItem('userData')


                                location.reload();
                              }else{

                                if (response.data.code == 404) {
                              //  if (response.data.code == 400) {
            
    
                                    this.showEmptyCripto=true;
                             
                                }else{
                                  if(response.data.code ==405){
                                    this.showSameWallet=true;
                                  }else{

                                    if(response.data.code ==406){
                                      this.showTrader=true;
                                      
                                    }else{
                                      this.searchQRWallets();
                                    }

                                   
                                    

                                  }
                                   
                                }
                              
                              }
                            }
                          }).catch(error => {

                            this.searchQRWallets();
                            
                          })


      
          },
      }
    }
      </script>
      
      <style lang="scss" >
      
    
      
      </style>
      
          