<template>
<div>


<b-tabs
                  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1 mt-md-0 sin_padding"
                  pills
                  fill  
                  nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 card3"
  


                  >


                  <b-tab >

                  <template #title>

              
                  <span class="texto">Billetera de EQCoop</span>


                  </template>

                  <b-card>

                    <b-alert
                        variant="primary"
                        show
                        style="text-align: center;"

                      >
                        <div class="alert-body">
                          <span> Realiza un pago rápido escaneando este código QR utilizando la app o el PWA de EQCoop</span>
                        </div>
                      </b-alert>

                      <b-col v-if="hideQR" align="center" cols="12" sm="12" md="12" lg="12" xl="12"> 

                          <b-skeleton-img   no-aspect height="200px" width="60%" animation="fade"></b-skeleton-img>


                          </b-col>

                  <div align="center" style="margin-top:10px; margin-bottom:10px">

                    <vue-qr style="width: 200px !important;"  :callback="getDataURLEQCoop" logoBackgroundColor="#fff" backgroundColor="#f0f2f5"  :logoScale="logoScale" :logoMargin="logoMargin" :logoCornerRadius="logoCornerRadius" :correctLevel="correctLevel2" :margin="margin"  :text="aliasScan" :size="size"></vue-qr>
                    </div>
                    <b-row>

                        <b-col  cols="3" sm="3" md="3" lg="3" xl="3" align="center" ></b-col>
                        <b-col  cols="3" sm="3" md="3" lg="3" xl="3" align="center" >

                                <b-link @click="downloadAlias()" class="icon_dashboard"> 


                                    <b-avatar
                                    size="40"
                                    variant="light-primary"
                                    style="cursor:pointer;">

                                    <span class="d-flex align-items-center">
                                        <feather-icon
                                        icon="DownloadIcon"
                                        size="21"
                                    />

                                    </span>

                                    </b-avatar>



                                    </b-link>



                                </b-col>


                                <b-col  cols="3" sm="3" md="3" lg="3" xl="3" align="center" >

                                <b-link @click="shared()" class="icon_dashboard"> 


                                    <b-avatar
                                    size="40"
                                    variant="light-primary"
                                    style="cursor:pointer;">

                                    <span class="d-flex align-items-center">
                                        <feather-icon
                                        icon="Share2Icon"
                                        size="21"
                                    />

                                    </span>

                                    </b-avatar>

                                

                                    </b-link>


                                </b-col>
                                <b-col  cols="3" sm="3" md="3" lg="3" xl="3" align="center" ></b-col>
                    </b-row>
  
                    
                    </b-card>

                 

                  </b-tab>

                  <b-tab >

                  <template #title >
           
                      <span class="texto">Billetera de USDT</span>



                  </template>

                <b-card>

                  <b-alert
                        variant="primary"
                        show
                        style="text-align: center;"

                      >
                        <div class="alert-body">
                          <span> Realiza un pago rápido escaneando este código QR utilizando la app o el PWA de EQCoop</span>
                        </div>
                      </b-alert>

                      <b-col v-if="hideQR" align="center" cols="12" sm="12" md="12" lg="12" xl="12"> 

                        <b-skeleton-img   no-aspect height="200px" width="60%" animation="fade"></b-skeleton-img>


                        </b-col>

                  <div align="center" style="margin-top:10px; margin-bottom:10px">

                    <vue-qr style="width: 200px !important;" :logoSrc="logoUSDT" :callback="getDataURLUSDT" logoBackgroundColor="#fff" backgroundColor="#f0f2f5"  :logoScale="logoScale" :logoCornerRadius="logoCornerRadius" :logoMargin="logoMargin" :correctLevel="correctLevel" :margin="margin"  :text="idWalletScan" :size="size"></vue-qr>
                    </div>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">

                            <b-input-group>

                            <b-form-input
                                v-model="addressU"
                                readonly
                            />
                            
                                <b-input-group-append>
                                <b-dropdown
                                variant="outline-primary"
                                right
                                >

                                <template  #button-content>
                                    
                                    Acciones <feather-icon
                                        icon="ChevronDownIcon"
                                        size="16"
                                        style="color:white"
                                        />

                                    </template>
                                    <b-dropdown-item   @click="onCopyAddressUSDT(addressU)"  >  <feather-icon
                                        icon="CopyIcon"
                                        size="15"
                                        style="margin-right: 10px;"
                                        /> Copiar texto </b-dropdown-item>
                                    <b-dropdown-item    @click="downloadAddressUSDT">  
                                    <feather-icon
                                                icon="DownloadCloudIcon"
                                                size="18"
                                                style="margin-right: 10px;"
                                                
                                                />Descargar imagen</b-dropdown-item>
                                </b-dropdown>
                                </b-input-group-append>

                                </b-input-group>




                            </b-col>

        
                    </b-card>
                  </b-tab>

                  </b-tabs>

                </div>
</template>
  
  <script>


  import { BAlert, BTab,BSkeletonImg, BTabs, BCard, BRow, BCol, BLink, BAvatar,  BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,  BDropdown,BDropdownItem, } from 'bootstrap-vue'
  import VueQr from 'vue-qr'
  export default {
    components: {
        BAlert,
        BTab,
        BTabs,
        BAvatar,
        BRow, BCol, BLink,
      VueQr,
      BCard,
      BSkeletonImg,
      BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,  BDropdown,BDropdownItem,

    },
    props: ['alias','idWalletU', 'photo', 'addressU'],
    data() {
      return {
        idWalletScan: this.idWalletU+";billetera",
        aliasScan: this.alias+";billetera",
        logoEQ:"/img/eqonly.svg",
        logoUSDT: "/img/usdt.svg",
        logoScale:0.3,
            logoMargin: 1,
            logoCornerRadius:100,
            size:800,
            margin:50,
            correctLevel:3,
            correctLevel2:2,
            dataUrlEQCoop:"",
            dataUrlUSDT:"",
            hideQR:true,
      }
    },
    computed: {
  
    },mounted(){


    }, methods: {
        getDataURLEQCoop(dataUrl,id){
      this.dataUrlEQCoop= dataUrl;

      this.hideQR=false;
    },

    onCopyAddressUSDT(copy) {

      
        // Copy the text inside the text field
        navigator.clipboard.writeText(copy);

          this.$toast.success('Address copiado exitosamente', {
          position: 'top-right',
          timeout: 1500,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
          })
    },


    downloadAddressUSDT(){
    
    var link = document.createElement("a");
    link.download = "Billetera USDT de " +  this.alias;
    link.href =this.dataUrlUSDT;
    link.click();
     
     

  },
        downloadAlias(){
    
                var link = document.createElement("a");
                link.download = "Billetera EQCoop de " +  this.alias;
                link.href =this.dataUrlEQCoop;
                link.click();
                
                

            },

            getDataURLUSDT(dataUrl,id){
      this.dataUrlUSDT= dataUrl;
      this.hideQR=false;
    },

            shared(){


    
fetch(this.dataUrlEQCoop).then(function (response) {
  return response.blob()
}).then(function (blob) {
  let file = new File([blob], "QR.jpg", {type: 'image/jpeg'});
  let filesArray = [file];
  if (navigator.canShare && navigator.canShare({files: filesArray})) {
    navigator.share({
      files: filesArray
    }).then(() => console.log('Successful share'))
      .catch((error) =>   this.$toast.error("Ocurrió un error inesperado", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      }));
  }else{

      this.$toast.error("Lo sentimos, tu navegador no es compatible para compartir este contenido", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })

  }
});




}
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
  </style>
  