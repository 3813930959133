<template>


<div>

    <b-row v-if="isLogeo">

      <b-modal
            ref="modalPaySuccess"
            centered
            hide-footer
            title="Pago realizado con éxito"
            @hidden="closeModal"
          >
            <div class="d-block text-center">

              <b-row>

                <b-col
                cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  align="center"
                  style="margin-bottom: 10px;"
                >

                <img  src="/img/check.svg" alt="Pago exitoso" />

                </b-col>

                  <b-col
                  cols="5"
                  sm="5"
                  md="5"
                  lg="5"
                  xl="5"
                  >

                  <div class="profile-image p-0">
                      <b-avatar
                        size="50"
                        variant="light"
                        :src="photo_user_pay"
                      />
                    </div>


                  <h6 :title="contacto" class="text-muted" style="cursor: default;margin-top: 5px;font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                    {{ contacto }}
                  </h6>

                </b-col>

                  <b-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  xl="2"
                  style="display: flex;"
                >

                <svg width="30" height="30"  class="flecha" viewBox="-32 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9.0L441 239c9.4 9.4 9.4 24.6.0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9.0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3.0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/></svg>

                </b-col>

                <b-col
                  cols="5"
                  sm="5"
                  md="5"
                  lg="5"
                  xl="5"
                >

                <div class="profile-image p-0">
                      <b-avatar
                        size="50"
                        variant="light"
                        :src="photo"
                      />
                    </div>


                  <h6 class="text-muted" style=" margin-top: 5px; font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                    @{{ alias }}
                  </h6>

                </b-col>


                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                <p style="text-align: center;font-size: 13px;margin-bottom: 5px;margin-top: 15px;">Enviado a tu contacto</p>

                    <h4 :title="displayName" style="    cursor: default;font-size:16px;margin-bottom: 0px;margin-top: 5px;    text-transform: uppercase; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" class="font-weight-bolder">
                    {{ displayName }}
                  </h4>

                  <p style="text-align: center;font-size: 30px;font-weight: bold;    margin-top: 15px;">${{mount}}</p>

                  <p style="text-align: center;font-size: 12px;margin: 0;">{{date}}</p>

                  <b-alert v-if="hasDescription" variant="primary" style="margin-top: 5px !important; " show>
                  <div class="alert-body">



                        <p style="font-size: 14px; margin-bottom:0;">{{description}}</p>


                  

                  
                  </div>
                </b-alert>



                </b-col>

                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  align="center"
                  style="margin-top:10px"
                >

                <b-button

                          variant="primary"
                          @click="closeModal"
                          
                        >

                          Entendido

                        </b-button>

                </b-col>


                </b-row>


              </div>


              </b-modal>


        <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="loading" style="padding:0px">

            <b-row>

  
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <b-skeleton width="100%"  height="80px"></b-skeleton>

                </b-col>

            
                 <b-col cols="12" sm="12" md="2" lg="2" xl="2"></b-col>

                        <b-col cols="12" sm="12" md="8" lg="8" xl="8">
                            <b-skeleton width="100%"  height="10px"></b-skeleton>

                        </b-col>

                        <b-col cols="12" sm="12" md="2" lg="2" xl="2"></b-col>

                        <b-col cols="12" sm="12" md="3" lg="3" xl="3"></b-col>

                        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <b-skeleton width="100%"  height="55px"></b-skeleton>

                        </b-col>

                        <b-col cols="12" sm="12" md="3" lg="3" xl="3"></b-col>

                        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <b-skeleton width="70%"  height="10px"></b-skeleton>

                        </b-col>

                        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <b-skeleton width="100%"  height="70px"></b-skeleton>

                        </b-col>

                        <b-col
                                cols="2"
                                    sm="2"
                                    md="2"
                                    lg="2"
                                    xl="2"
                                > </b-col>


                                <b-col
                                cols="8"
                                    sm="8"
                                    md="8"
                                    lg="8"
                                    xl="8"
                                > 

                                <b-skeleton width="100%"  height="10px"></b-skeleton>

                                <table width="100%">
                                    <tr>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    </tr>
                                </table>

                                
                                </b-col>

                                <b-col
                                cols="2"
                                    sm="2"
                                    md="2"
                                    lg="2"
                                    xl="2"
                                >   </b-col>


                                <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                > </b-col>


                                <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                >

                                <b-skeleton width="100%"  height="40px"></b-skeleton>
                                
                                </b-col>


                                <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                > </b-col>


            </b-row>



        </b-col>

        <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-else style="padding:0px">

          <b-row>



            <b-col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        
                        style="padding:0px"
                      >

                        <b-alert
                          variant="secondary"
                          show
                          style="margin-bottom: 10px !important;"
                        >
                          <div class="alert-body">
                            
                                <p class="saldoDisponible"><span style="font-size:25px">$</span>{{saldoDisponible}}<small class="currency_cripto">USD</small></p>
                              <p style="font-size: 12px; text-align: center;   margin: 0;">Saldo disponible para transferir</p>


                          
                          </div>
                        </b-alert>


                        </b-col>


                      
                      <b-form
                      autocomplete="off"
                      @submit.prevent="onSubmit"
                      >
                      <b-row>



                      <b-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >

                

           

                                  <p
                          class="textLabel"
                          style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
                        >
                          Monto en dólares a enviar (*):</p>

                          <AutoNumericVue
                          v-model="mount"
                          required
                          type="text" inputmode="decimal"
                          :readonly="isDisabled"
                          :autofocus="false"
                          @input="onInput"
                          @blur="onBlurMount"
                          class="autonumeric_input"
                          :options="json_opciones"
                          :placeholder="'$0.00'"
                      ></AutoNumericVue>

                      <p style="margin-bottom:10px; text-align: center;font-size:11px; font-style:italic">El valor ingresado debe ser en dólares americanos.</p>

                      </b-col>

                      <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      >
                        <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                          Descripción de la transferencia (Opcional):</p>

                            <b-form-textarea
                          v-model="description"
                          placeholder="Ingrese una descripción corta de la transferencia (Opcional)"
                          rows="3"
                          no-resize
                          :readonly="isDisabled"
                          :state="description.length <= maxChar"
                          class="char-textarea"
                          :class="description.length >= maxChar ? 'text-danger' : ''"
                        />
                        <small
                          class="textarea-counter-value float-left"
                          :class="description.length >= maxChar ? 'bg-danger' : ''"
                        >
                          <span class="char-count">{{ description.length }}</span> / {{ maxChar }}
                        </small>

                      </b-col>

                      <b-col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <p
                          class="textLabel"
                          style="text-align: center;margin-bottom: 10px;"
                        >
                          Ingrese su código PIN (*): <feather-icon
                                            icon="HelpCircleIcon"
                                            size="16"
                                              v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                            title="Código PIN de seguridad"
                                            style="margin-right: 5px;cursor:pointer;"
                                            /> </p>

                        <PincodeInput
                          v-model="pin"
                          placeholder="-"
                          :length="6"
                          :autofocus="false"
                            :disabled="isDisabled"
                          :secure="true"
                          required
                        />

                      </b-col>




                      <b-col
                      cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        
                      >

                        <b-button
                          id="btn_transferir"
                          ref="btn_transferir"
                          type="submit"
                          variant="primary"
                          block
                          :disabled="isDisabled"
                          style="border-radius:50px;justify-content: space-between;display: flex"
                    
                 
                        >

                         <span style="font-size: 16px;">Pagar con <img style="width: 45px;" src="/img/eqcoopblanco.svg" /></span> 
                         <span style="font-size: 16px;">${{mountFormated}} USD</span> 
                        </b-button>

                      </b-col>

        


                      </b-row>

                      </b-form>

        
                    </b-row>




    </b-col>

    </b-row>


    <b-row v-else >

  

        <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="padding:0px">
          <feather-icon
                        icon="ThumbsUpIcon"
                        size="70"
                        class="color_icon"
                        />
            <p style="font-size: 15px;    margin-bottom: 0px; margin-top: 10px">Inicie sesión para realizarle un pago a este usuario</p>


            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="margin-top:15px">
              <b-button
                          variant="primary"
                          @click="login"
                          class="animacion_button"
                        >

                        <feather-icon
                                icon="LogInIcon"
                                size="15"
                                style="margin-right: 5px;"
                                /> Iniciar sesión

                        </b-button>

              </b-col>


    </b-row>



</div>
  </template>
  
  <script>
  
      /* eslint-disable global-require */
  import {  BRow, BCol, BButton,BAvatar, BSkeleton,BFormTextarea, BAlert, BForm,VBPopover,VBTooltip } from 'bootstrap-vue'
  import PincodeInput from 'vue-pincode-input'
  import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';

  export default {
      components: {
      BRow,
      BAvatar,
      BCol,
      BSkeleton,
      BButton,
      BFormTextarea,
      PincodeInput,
      BAlert, BForm,
      AutoNumericVue
      },
      props: ['alias', 'photo','displayName','isLogeo'],
      directives: {
      'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
      data() {
  
       

        
          let userId= "";
          let tokenAuth="";
          let photo_user_pay="";
          let contacto="";

          if(this.isLogeo){
            const user = localStorage.getItem('userData');
            const datos_json = JSON.parse(user);

              tokenAuth = datos_json.tokenAuth;
              userId=  datos_json.userId;
               photo_user_pay= datos_json.photoUser

               if(datos_json.phone ===""){

                contacto= datos_json.email;

               }else{
                contacto= datos_json.phone;
               }

          }


       
    
  
      
      return {
          userId:userId,
          tokenAuth:tokenAuth,
          loading:true,
          saldoDisponible: 0,
          maxChar: 75,
          mount:"",
          mountFormated:"0.00",
          isDisabled:false,
          json_opciones:{},
          description:"",
          pin:"",
          date:"",
          hasDescription:false,
          photo_user_pay:photo_user_pay,
          contacto:contacto

      }
      },
      created() {
  
      },
      watch: {
  
          
  
          },
      mounted() {

        if(this.isLogeo){

            this.json_opciones={allowDecimalPadding: "false",currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1000000, modifyValueOnWheel: false };
  

            this.getBalance();

           


        }
  
  
  
  
      
  
      },
      methods: {

        closeModal(){
          this.$refs.modalPaySuccess.hide();
          this.$eventBus.$emit('reiniciarSaldoEQCoop');
        },

        login(){

          this.$eventBus.$emit('showModalLogin');

        },
        onSubmit(event) {

                        
                event.preventDefault()

                if(Number(this.description.length) > Number(this.maxChar)){

                this.description="";

                        this.$toast.error("El texto de la descripción sobrepasa el límite permitido de 75 caracteres", {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                    })

                }else{

                this.mount= Number(this.mount).toFixed(2);

                if(Number(this.description.length) > Number("0")){
                    this.hasDescription=true;

                  }else{
                    this.hasDescription=false;
                  }


                const userId_json = {
                userIdWhoPay: this.userId, pin: this.pin, mount: this.mount, description: this.description, dataScan:this.alias, origen: "billetera"
                }



                const user_string = JSON.stringify(userId_json)

                const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

                this.isDisabled = true

                document.getElementById('btn_transferir').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Transferiendo'

                this.$https.post('/interbilletera/payWithQR/', { tokenAuth: this.tokenAuth, data: dataEncripted}).then(response => {
                this.isDisabled = false



                document.getElementById('btn_transferir').innerHTML = '<span style="font-size: 16px;">Pagar con <img style="width: 55px;" src="/img/eqcoopblanco.svg" /></span>   <span style="font-size: 16px;">$'+this.mount+' USD</span> ';
             

                if (response.data.code == 200) {

                    this.date= response.data.date;

                    this.$refs.modalPaySuccess.show();

                  
                    

                } else {
                    this.isDisabled = false

                    this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                    })

                    if (response.data.code == 401) {
                    
                    localStorage.removeItem('userData')

                    location.reload();

                    } else if (response.data.code == 503) {
                    this.pin = ''
                    }else if(response.data.code == 501){
                    this.$swal({
                        title: 'Tu perfil debe estar verificado para realizar esta acción',
                        text: `¿Deseas verificar tu cuenta ahora?`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Verificar cuenta',
                        cancelButtonText: 'No',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-secondary ml-1',
                        },
                        buttonsStyling: false,
                        }).then(result => {
                        if (result.value) {

                            this.$router.push({ name: 'perfil', hash: '#verificacion' })

                        }
                        })
                    }
                }
                }).catch(error => {

          
                this.$toast.error("Ocurrió un error inesperado", {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                })
                })

                }



                },

                onInput(value){


                  if(value === null  ){

                      
                      

                          this.mountFormated="0.00";




                      }else{

                        if(Number.isInteger(value)){

                          this.mountFormated=value+".00";

                        }else{
                          this.mountFormated=value;
                        }

                           

                          


                      }

                  },

        onBlurMount(){
        if(this.mount == null || this.mount == "null" || this.mount ==""){



            }else{

                  if(Number(this.mount) > Number(this.saldoDisponible)){
                  

                      this.mount="";

                      this.$toast.error("La cantidad a enviar es mayor a su saldo disponible", {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                          })

              


                  }




            }
  
      },
      getBalance(){
      this.loading=true;
        const userIdEncripted = this.$encryptBackoffice.encrypt(this.userId)

    this.$https.post('/links/getBalance/', { tokenAuth: this.tokenAuth, userId: userIdEncripted }).then(response => {
        

       
      if (response.data.code == 200) {
        this.loading = false;

          this.saldoDisponible= response.data.balance;
    

      } else {

        this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })


        if (response.data.code == 401) {

               
          
          localStorage.removeItem('userData')


          location.reload();
        } else {
           this.getBalance();
        }
      }
    }).catch(error => {
         this.getBalance();
    })
    }
  
      },
  }
  </script>
  
  <style lang="scss" >
  

  
  </style>
  
      