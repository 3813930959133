<template>


    <div>


        <b-row v-if="loading">
          <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">
            <b-card>

                <b-row>

                       


                <b-col cols="3" sm="3" md="3" lg="3" xl="3"></b-col>

                <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <b-skeleton width="100%"  height="10px"></b-skeleton>

                </b-col>

                <b-col cols="3" sm="3" md="3" lg="3" xl="3"></b-col>

                <b-col cols="2" sm="2" md="2" lg="2" xl="2"></b-col>

                <b-col cols="8" sm="8" md="8" lg="8" xl="8">
                  <b-skeleton width="100%"  height="40px"></b-skeleton>

                </b-col>

                <b-col cols="2" sm="2" md="2" lg="2" xl="2"></b-col>

                <b-col cols="4" sm="4" md="4" lg="4" xl="4">
                  <b-skeleton width="100%"  height="10px"></b-skeleton>
                </b-col>


                <b-col cols="8" sm="8" md="8" lg="8" xl="8"></b-col>


                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-skeleton width="100%"  height="80px"></b-skeleton>

                </b-col>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-skeleton width="100%"  height="30px"></b-skeleton>

                </b-col>

            </b-row>

                </b-card>

                </b-col>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">
                  <b-skeleton width="100%"  height="40px"></b-skeleton>

                </b-col>

    
        </b-row>
    
    
        <b-row v-else-if="isEcuadorian" >
          <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">
          
            <b-card>

                <b-row>

   

                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  
                  style="padding:0px"
                >
                
                <b-form
                autocomplete="off"
                @submit.prevent="onSubmit"
                >
                <b-row>



                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >

          

     

                            <p
                    class="textLabel"
                    style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
                  >
                    ¿Cuánto deseas pagar? (*):</p>

                    <AutoNumericVue
                    v-model="mount"
                    required
                    type="text" inputmode="decimal"
                    :readonly="isDisabled"
                    :autofocus="false"
                    @input="onInput"
                    class="autonumeric_input"
                    :options="json_opciones"
                    :placeholder="'$0.00'"
                ></AutoNumericVue>

                </b-col>

                <b-col
                sm="12"
                md="12"
                lg="6"
                xl="6"
                v-if="!hasDNI"
                >
                <p class="textLabel" style="text-align: left;margin-top: 10px;margin-bottom: 5px;">
                  Ingrese su cédula (*):</p>
                <b-form-input
                v-model="dni"
                    placeholder="Ingrese su cédula"
                    required
                    @keypress="NumbersOnly"
                     type="tel"
                    :readonly="isDisabled"
                    @blur="checkDNI"
                />

                </b-col>
                <b-col
                sm="12"
                md="12"
                lg="6"
                xl="6"
                v-if="!hasDNI"
                >
                <p class="textLabel" style="text-align: left;margin-top: 10px;margin-bottom: 5px;">
                    Nombres y apellidos (*):</p>
                <b-form-input
                  v-model="displayName"
                  placeholder="Ingrese sus nombres y apellidos"
                  required
                  type="text"
                  readonly
                  @keypress="isLetter"
                />

                </b-col>

                <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
                >
                  <p class="textLabel" style="text-align: left;margin-top: 10px;margin-bottom: 5px;">
                    Dedicatoria del pago (Opcional):</p>

                      <b-form-textarea
                    v-model="description"
                    placeholder="Ingrese un motivo corto del pago (Opcional)"
                    rows="3"
                    no-resize
                    :readonly="isDisabled"
                    :state="description.length <= maxChar"
                    class="char-textarea"
                    :class="description.length >= maxChar ? 'text-danger' : ''"
                  />
                  <small
                    class="textarea-counter-value float-left"
                    :class="description.length >= maxChar ? 'bg-danger' : ''"
                  >
                    <span class="char-count">{{ description.length }}</span> / {{ maxChar }}
                  </small>

                </b-col>

      

                <b-col
                cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-top:15px"
                >

                <b-button
                          id="btn_wester"
                          ref="btn_wester"
                          type="submit"
                          variant="primary"
                          block
                          :disabled="isDisabled"
                          style="border-radius:50px;justify-content: space-between;display: flex"
                    
                 
                        >

                         <span style="font-size: 16px;">Generar código de pago</span> 
                         <span style="font-size: 16px;">${{mountFormated}}</span> 
                        </b-button>

                </b-col>

          

                        


                                        </b-row>

                                        </b-form>

                                    </b-col>
                                    

           

                </b-row>

                </b-card>

                <b-col sm="12" md="12" lg="12" xl="12" style="padding:0px; margin-bottom: 15px;" >

                                                                
                    <app-collapse
                    accordion
                    type="margin"
                    class="address_card"
                    >

                    <app-collapse-item title="Pasos para pagar con Red Activa / WU" icon="ListIcon">

                        <b-row>

                        <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">

                          <b-avatar
                                    size="25"
                                    variant="light-primary"
                                    style="margin:auto;background: #00838c !important;color: white !important;">

                                    <span class="d-flex align-items-center">
                                     1

                                    </span>

                                    </b-avatar>
                       

                            </b-col>
                            <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
                            
                            <p style="text-align:left;margin:0px">Digite la cantidad a pagar y genere un código de pago.</p>

                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <hr style="margin:10px">
                            </b-col>


                            <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">
                              <b-avatar
                                    size="25"
                                    variant="light-primary"
                                    style="margin:auto;background: #00838c !important;color: white !important;">

                                    <span class="d-flex align-items-center">
                                     2

                                    </span>

                                    </b-avatar>
                       

                                </b-col>
                                <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
                        
                            <p style="text-align: left;margin:0px">Presente el código generado en cualquier punto de <a style="color: #00838C;font-weight: bold;" href="https://redactiva.com/agencias/" target="_blank"> Red Activa / Wester Unión </a> y cancele el valor.</p>


                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <hr style="margin:10px">
                            </b-col>

                            <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">
                              <b-avatar
                                    size="25"
                                    variant="light-primary"
                                    style="margin:auto;background: #00838c !important;color: white !important;">

                                    <span class="d-flex align-items-center">
                                     3

                                    </span>

                                    </b-avatar>
                       

                      </b-col>
                      <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

                            <p style="text-align: left;margin:0px">Su pago será procesado y acreditado al usuario de EQCoop en un plazo máximo de 30 minutos.</p>



                        </b-col>
                        </b-row>
                    </app-collapse-item>

                    </app-collapse>

                    </b-col>

       
        </b-col>

        <b-modal
            ref="modalPaySuccessWester"
            centered
            hide-footer
            title="Pago realizado con éxito"
          >
            <div class="d-block text-center">

              <b-row>

                <b-col sm="12" md="12" lg="12" xl="12">
                  <img src="/img/eqcoop.svg" width="100px"/>
                </b-col>



                <b-col sm="12" md="12" lg="12" xl="12" ref="printMe" class="background_download">

                
                <p style="text-align: center;font-size:14px;margin-bottom: 5px;">Su código para realizar el pago de ${{mountPay}} es: </p>

                <b-alert
                        variant="secondary"
                        show
                        style="margin-bottom: 10px !important;"
                      >
                        <div class="alert-body">
                          
                          <p class="saldoDisponible" style="margin-bottom: 10px;">{{CodeGenerate}}</p>

                        
                        </div>
                      </b-alert>

                      <p style="text-align: center;font-size:14px;margin-bottom: 5px;">Debe realizar el pago antes del: <br> <b>{{DateExpiration}} </b></p>

                </b-col>

                <b-col cols="6" sm="6" md="6" lg="6" xl="6" align="center" style="margin-top: 10px; margin-bottom: 10px;">

                
                        <b-button @click="descargar" variant="primary" :disabled="isDisabledDescargar">

                                    <feather-icon
                                icon="DownloadIcon"
                                size="16"
                                style="margin-right: 10px;"
                                
                                /> 

                                    Descargar

                                    </b-button>

                                
                        </b-col>

                        <b-col cols="6" sm="6" md="6" lg="6" xl="6" align="center" style="margin-top: 10px; margin-bottom: 10px;">

                
                            <b-button @click="shared" variant="primary" :disabled="isDisabledDescargar">

                                        <feather-icon
                                    icon="Share2Icon"
                                    size="16"
                                    style="margin-right: 10px;"
                                    
                                    
                                    /> 

                                        Compartir

                                        </b-button>

                                    
                            </b-col>

                  <b-col sm="12" md="12" lg="12" xl="12" >


                      <app-collapse
                        accordion
                        type="margin"
                      >

                        <app-collapse-item title="¿Dónde puedo realizar el pago?" icon="InfoIcon">
                          <p style="margin: 0;">  Presenta el código de pago generado en cualquier punto de <a style="color: #00838c;font-weight: bold;" href="https://redactiva.com/agencias/" target="_blank"> Red Activa / Wester Unión </a> para realizar el pago correspondiente.</p>

                        </app-collapse-item>

                        <app-collapse-item title="¿Hasta cuándo puedo pagar?" icon="InfoIcon">

                          <p style="margin: 0;">Ten en cuenta la fecha de vencimiento de este comprobante ya que no podrás utilizarlo si se encuentra vencido.</p>


                            </app-collapse-item>

                        <app-collapse-item title="¿Qué debo hacer luego de realizar el pago?" icon="InfoIcon">
                          <p style="margin: 0;">No tienes que hacer nada más, nosotros procesaremos el pago y le notificaremos al usuario que recibió un pago.</p>
                        </app-collapse-item>

                      </app-collapse>

                  </b-col>


                </b-row>

              </div>


              </b-modal>

        </b-row>

            <b-row v-else>
    
    
    
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="padding:0px">

              <b-card>
                <img style="    width: 100px; margin-bottom:15px" src="/img/ecuador.svg" />
    
             
                <b-alert
                          variant="secondary"
                          show
                          style="margin-bottom: 10px !important;"
                        >
                          <div class="alert-body">
                            
                            <p style="font-size: 14px;">Lo sentimos, este método de pago solo está disponible para Ecuador, por favor intente realizar su pago con otra opción.</p>
    

                          
                          </div>
                        </b-alert>

              </b-card>
                </b-col>
             
    
    
        </b-row>
    
    
    
    </div>
      </template>
      
      <script>
      
          /* eslint-disable global-require */
      import {  BFormInput, BRow, BCol, BButton, BSkeleton,BFormTextarea, BAlert,BCard,BAvatar, BForm,VBPopover,VBTooltip } from 'bootstrap-vue'
      import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
      import AppCollapse from '@core/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/app-collapse/AppCollapseItem.vue'
import domtoimage from "dom-to-image-more";

      export default {
          components: {
          BRow,
          
          BAvatar,
          BCol,
          BCard,
          BSkeleton,
          BButton,
          BFormInput,
          BFormTextarea,
          BAlert, BForm,
          domtoimage,
          AutoNumericVue,
          AppCollapse,
          AppCollapseItem
          },
          directives: {
          'b-popover': VBPopover,
          'b-tooltip': VBTooltip,
        },
        props: ['alias', 'isLogeo', 'tokenAuth'],
          data() {

          
            let displayName="";

            let dni="";
            let hasDNI=false;

            if(this.isLogeo){
              const user = localStorage.getItem('userData');
              const datos_json = JSON.parse(user);
  
              dni=  datos_json.dni;

                if(dni === ""){

                  hasDNI=false;
                  displayName="";
                }else{
                  hasDNI=true;
                  displayName=  datos_json.displayName;
                }

            }




        
    
      
          
          return {
              loading:true,
              isEcuadorian:false,
              maxChar: 75,
              mount:"",
              dni:dni,
              hasDNI:hasDNI,
              mountFormated:"0.00",
              isDisabled:false,
              json_opciones:{},
              description:"",
              CodeGenerate:"",
              mountPay:0,
              DateExpiration:"",
              isDisabledDescargar:false,
              displayName: displayName,
              dniRespaldo: '',
   
          }
          },
          created() {
      
          },
          watch: {
      
              
      
              },
          async mounted() {
    
    
    
                this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",  minimumValue: 0, maximumValue:1000000, modifyValueOnWheel: false };
      
    
                this.countryPay();


      
      
          },
          methods: {

           

            NumbersOnly(evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
          checkDNI() {
            if (this.dni == '') {

            } else if (this.dni != this.dniRespaldo) {
              this.$swal({

                html:
                                    '<img style="margin-left:10px; width:30px " src="/img/loading.svg" alt="Loading" /> Comprobando cédula, espere',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                position: 'center',
              })

              const dataEncripted = this.$encryptBackoffice.encrypt(this.dni)


              this.$https.post('/profile/consultaDatos/', {  cedula: dataEncripted }).then(response => {
                this.$swal.close()

                if (response.data.code == 200) {

              
                  this.dniRespaldo = this.dni

                  this.displayName = response.data.dataGeneral.nombres.trim()
                
                } else {
                  this.dni = ''
                  this.displayName = ''
                  this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })
                }
              }).catch(error => {
                this.dni = ''
                this.displayName = ''
                this.$toast.error("Ocurrió un error inesperado", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
              })
            }
          },


            async shared(){

              const node = this.$refs.printMe;

              const scale = 750 / node.offsetWidth;



                let base64= await domtoimage
                      .toPng(node, {
                          quality: 1,
                          height: node.offsetHeight * scale,
                          width: node.offsetWidth * scale,
                          style: {
                              transform: "scale(" + scale + ")",
                              transformOrigin: "top left",
                              width: node.offsetWidth + "px",
                              height: node.offsetHeight + "px"
                          }})
                      .then(function (dataUrl) {

                          return dataUrl;


              
                      });


                  fetch(base64).then(function (response) {
                  return response.blob()
                  }).then(function (blob) {
                  let file = new File([blob], "Codigo.png", {type: 'image/png'});
                  let filesArray = [file];
                  if (navigator.canShare && navigator.canShare({files: filesArray})) {
                      navigator.share({
                      files: filesArray
                      }).then(() => console.log('Successful share'))
                      .catch((error) =>   this.$toast.error("Ocurrió un error inesperado", {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                      }));
                  }else{

                      this.$toast.error("Lo sentimos, tu navegador no es compatible para compartir este contenido", {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                      })

                  }
                  });




                  },
                  isLetter(e) {
      const char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[a-zA-Z\s]*$/g.test(char)) return true // Match with regex
      e.preventDefault() // If not match, don't add to input text
    },
              async descargar(){



                  const node = this.$refs.printMe;


                  const scale = 750 / node.offsetWidth;


                  this.isDisabledDescargar=true;


   let base64= await domtoimage
        .toPng(node,{
                    quality: 1,
            height: node.offsetHeight * scale,
            width: node.offsetWidth * scale,
            style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
            width: node.offsetWidth + "px",
            height: node.offsetHeight + "px"
            }})
        .then(function (dataUrl) {

            return dataUrl;


 
        })
        .catch(function (error) {
        console.error("oops, something went wrong!", error);
        });

  
        var link = document.createElement("a");
            link.download = this.CodeGenerate+".png";
            link.href = base64;
            link.click();
            this.isDisabledDescargar=false;



    },

    
          
            onSubmit(event) {
    
                            
                    event.preventDefault()
    
                    if(Number(this.description.length) > Number(this.maxChar)){
    
                    this.description="";
    
                            this.$toast.error("El texto de la descripción sobrepasa el límite permitido de 75 caracteres", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        })
    
                    }else{
    
                    this.mount= Number(this.mount).toFixed(2);

    
                    this.mountPay= this.mount;
  
    
                    const userId_json = {
                     mount: this.mount,idLink:null, description: this.description, displayName: this.displayName,dni:this.dni, alias: this.alias
                    }
    
    
    
                    const user_string = JSON.stringify(userId_json)
    
                    const dataEncripted = this.$encryptWester.encrypt(user_string)
    
                    this.isDisabled = true
    
                    document.getElementById('btn_wester').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Generando código de pago'
    
                    this.$httpsWester.post('/red_activa/payLinkBilletera/', { tokenAuth: this.tokenAuth, data: dataEncripted}).then(response => {
                    this.isDisabled = false
    
                    
                     
    
                    document.getElementById('btn_wester').innerHTML = '<span style="font-size: 16px;">Generar código de pago</span>     <span style="font-size: 16px;">$'+this.mount+'</span> ';
                 
    
                    if (response.data.code == 200) {

                      this.CodeGenerate= response.data.CodeGenerate;
                       this.DateExpiration= response.data.DateExpiration;
                      
    
               
                        document.getElementById('btn_wester').innerHTML = '<span style="font-size: 16px;">Generar código de pago</span>     <span style="font-size: 16px;">$0.00</span> ';
                 
                        this.$refs.modalPaySuccessWester.show();
                     
                        this.mount= "";
                        this.description= "";
                        if(!this.hasDNI){
                          this.displayName="";
                          this.dni="";
                        }
                        
                        
    
                    } else {
                        this.isDisabled = false
    
                        this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        })
    
                        if (response.data.code == 401) {
                        
                        localStorage.removeItem('userData')

                       
    
                        
                        
    
                        
    
                        
                        
    
                        this.$eventBus.$emit('reiniciarWesterForm')
                          this.$eventBus.$emit('reiniciarAllFormsPay');
    
                        } 
                    }
                    }).catch(error => {
    
              
                    this.$toast.error("Ocurrió un error inesperado", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })
                    })
    
                    }
    
    
    
                    },
    
                    onInput(value){
    
    
                      if(value === null  ){
    
                          
                          
    
                              this.mountFormated="0.00";
    
    
    
    
                          }else{
    
                            if(Number.isInteger(value)){
    
                              this.mountFormated=value+".00";
    
                            }else{
                              this.mountFormated=value;
                            }
    
                               
    
                              
    
    
                          }
    
                      },
    
         
          countryPay(){
          this.loading=true;
            
                this.$https.post('/profile/countryPay/').then(response => {

                if (response.data.code == 200) {
                    this.loading = false;
            
                    let country= response.data.country;

                    if(country === "EC"){
                        this.isEcuadorian=true;

                    }else{
                        this.isEcuadorian=false;
                    }
                
            
                } else {
                   // this.countryPay();
                }
                }).catch(error => {
                  //  this.countryPay();
                })
            }
      
          },
      }
      </script>
      
      <style lang="scss" >
      
    
      
      </style>
      
          