<template>

<div :key="componentKeyBilletera">
    <b-row v-if="existeAlias">

      <b-modal
            ref="modalShowQR"
            centered
            hide-footer
            title="Pagos rápidos con QR"
            size="sm"
            @hidden="closeModalQR"
          >
          <wallets v-if="showQRDiv" :alias="alias" :address-u="addressU" :photo="photo" :id-wallet-u="idWalletU" />



          </b-modal>
      

       
          <b-col id="menu_fixed"  class="fixed_header_link sin_background"  sm="12" md="12" lg="12" xl="12">

           <b-row>

     
              <b-col cols="2" sm="2" md="2" xl="2" lg="2" style="text-align:left">
              <b-link
                      :to="{ path: '/'}"
                    > <img id="logoEQ" class="logoSVG2"/> </b-link>
              

            </b-col>

            <b-col cols="8" sm="9" md="9" xl="9" lg="9"  ></b-col>

            <b-col  cols="2" sm="1" md="1" xl="1" lg="1" style="text-align: right;" >

              <b-skeleton  v-if="loading" width="35px" height="35px" type="avatar"  ></b-skeleton>
            <login v-else  :is-login="isLogin" :is-logeo="isLogeo" :is-mobile="isMobile"  />

              

                    
            </b-col>


     

          
          </b-row>

          </b-col>
            


        <b-col cols="12" sm="12" md="12" xl="4" lg="5" class="sin_padding2">


        <b-card
        :img-src="banner"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile "
      >

        <b-row>

  

            <b-col cols="4" sm="4" md="4" xl="4" lg="4" style="padding:0px">



                <div class="profile-image p-0" style="text-align: left;">

          


                <b-avatar
                variant="light"
                :src="photo"
                class="border_profile"
                />
                </div>

            </b-col>


            <b-col cols="8" sm="8" md="8" xl="8" lg="8"  style="padding:0px">

              <b-row v-if="loading">

            <b-col cols="8" sm="8" md="8" xl="8" lg="8" style="padding:5px">
             
            </b-col>

            <b-col cols="2" sm="2" md="2" xl="2" lg="2" style="padding:5px">
                      <b-skeleton  width="35px" height="35px" type="avatar" style="margin:0" ></b-skeleton>

            </b-col>

            <b-col cols="2" sm="2" md="2" xl="2" lg="2" style="padding:5px">
                      <b-skeleton  width="35px" height="35px" type="avatar" style="margin:0" ></b-skeleton>

            </b-col>


            </b-row>

              <b-row v-else style="margin-top: 5px;">

                  <b-col cols="6" sm="6" md="6" xl="6" lg="6" style="padding:0px">

                  
                    </b-col>

                    
                    <b-col cols="3" sm="3" md="3" xl="3" lg="3" >

                      <b-link  style="cursor:pointer;    margin: auto;" @click="showQR" class="d-block d-sm-block d-md-block d-lg-block d-xl-none" >

                          <b-avatar
                          size="35"
                          variant="light-primary"



                          >

                          <span class="d-flex align-items-center">

                            <svg class="icons_colors"  width="18" height="18" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M4 4h4V2H2v6h2zM4 16H2v6h6v-2H4zm16 4h-4v2h6v-6h-2zM16 4h4v4h2V2h-6z"/><path d="M5 11h6V5H5v6zm2-4h2v2H7V7zM5 19h6v-6H5v6zm2-4h2v2H7v-2zM19 5h-6v6h6V5zm-2 4h-2V7h2v2zm-4 4h2v2h-2zm2 2h2v2h-2zm2 2h2v2h-2zm0-4h2v2h-2z"/></svg>


                          </span>

                          </b-avatar>

                          </b-link>


                      </b-col>

                      <b-col cols="3" sm="3" md="3" xl="3" lg="3" >

                      <b-link style="cursor:pointer;    margin: auto;" @click="shared" >

                        <b-avatar
                        size="35"
                        variant="light-primary"



                        >

                        <span class="d-flex align-items-center">


                            <feather-icon

                        icon="Share2Icon"
                        size="18"
                        class="color_icon"

                        />

                        </span>

                        </b-avatar>

                        </b-link>


                      </b-col>


                  </b-row>

          
             
         

            </b-col>


   

            <b-col cols="12" sm="12" md="12" xl="12" lg="12"  v-if="loading">

              <b-row>

                <b-col cols="12" sm="12" md="12" xl="12" lg="12">
                  <b-skeleton  width="100%" height="20px" style="margin:0" ></b-skeleton>
                </b-col>

                <b-col cols="6" sm="6" md="6" xl="6" lg="6">

                  <b-skeleton  width="80%" height="10px" style="margin-top:10px" ></b-skeleton>

                  <b-skeleton  width="50%" height="10px" style="margin-top:10px" ></b-skeleton>

                </b-col>

                <b-col cols="6" sm="6" md="6" xl="6" lg="6" style="margin-top:10px">

                  <b-row>

                    <b-col cols="3" sm="3" md="3" xl="3" lg="3">
                          <b-skeleton  width="35px" height="35px"  type="avatar"  style="margin:0" ></b-skeleton>

                      </b-col>

                      <b-col cols="3" sm="3" md="3" xl="3" lg="3">
                          <b-skeleton  width="35px" height="35px" type="avatar"  style="margin:0" ></b-skeleton>

                      </b-col>

                      <b-col cols="3" sm="3" md="3" xl="3" lg="3">
                          <b-skeleton  width="35px" height="35px" type="avatar"  style="margin:0" ></b-skeleton>

                      </b-col>
                      <b-col cols="3" sm="3" md="3" xl="3" lg="3">
                          <b-skeleton  width="35px" height="35px" type="avatar"  style="margin:0" ></b-skeleton>

                      </b-col>
                  </b-row>

                  </b-col>


              

              </b-row>


            
            </b-col>


            <b-col cols="12" sm="12" md="12" xl="12" lg="12"  v-else style="padding: 0px;">

              <b-row>

                <b-col cols="12" sm="12" md="12" xl="12" lg="12"  style="margin-top:5px" v-if="loading">

                    <h4 class="font-weight-bolder">
                      <b-skeleton  width="200px" height="10px" style="margin:0" ></b-skeleton>

                    </h4>

                    <h6

                      class="text-muted"
                    >

                    <b-skeleton  width="150px" height="10px" style="margin:0" ></b-skeleton>


                    </h6>


                  </b-col>

                  <b-col cols="12" sm="12" md="12" xl="12" lg="12"  v-else >

                          <h4 v-if="displayName" style="text-transform: capitalize; font-weight: bold;font-size:16px" class=" h4_title">
                          {{ displayName }}
                          </h4>

                          <h6
                          
                          v-if="alias"
                          style="text-align: left; color: #adadad !important; font-size:12px"
                          >
                          @{{ alias }}         <img v-if="isProfileVerificated" title="Usuario verificado" alt="Usuario verificado" class="badgeVerificacion2" src="/img/verificadoFinal.svg" />
                    <img v-else alt="Usuario no verificado" title="Usuario no verificado" class="badgeVerificacion2" src="/img/no_verificadoFinal.svg" />

                          </h6>


                        </b-col>

               

                  <b-col v-if="biografia" cols="12" sm="12" md="12" xl="12" lg="12" class="biografiatext" style="margin-bottom: 10px;">

                    <read-more more-str="Más información" :text="biografia" link="#" less-str="Menos información" :max-chars="100"></read-more>

            
                  </b-col>

                  <b-col cols="6" sm="6" md="6" xl="6" lg="6">

                                
                      <p style="margin: 0; text-align: left; font-size: 12px; color: #6c6c6c;">
                        <feather-icon
                      icon="CalendarIcon"
                      size="16"
                      /> Se unió el {{registro}}
                      </p>
                      
                      <p v-if="ubicacion" style="margin: 0; text-align: left; font-size: 12px; color: #6c6c6c;">
                        <feather-icon
                      icon="MapPinIcon"
                      size="16"
                      /> {{ubicacion}}
                      </p>


                  </b-col>

                  <b-col cols="6" sm="6" md="6" xl="6" lg="6" style="padding: 0px">
                    <b-row >

                      <b-col cols="3" sm="3" md="3" xl="3" lg="3" v-if="facebook" style="text-align: center; padding: 0px  ">

                            <a class="icon_dashboard" :href="facebook" target="blank" > 

                              <b-avatar
                              size="35"
                              variant="light-primary"
                              style="cursor:pointer;">

                              <span class="d-flex align-items-center">


                                <feather-icon
                              icon="FacebookIcon"
                              size="18"
                              class="color_redes_link"
                              />

                              </span>

                              </b-avatar>
                            
                            </a>


                                
                            </b-col>

                            <b-col cols="3" sm="3" md="3" xl="3" lg="3" v-if="twitter" style="text-align: center; padding: 0px">
                            <a class="icon_dashboard" :href="twitter" target="blank" > 

                                    <b-avatar
                                    size="35"
                                    variant="light-primary"
                                    style="cursor:pointer;">

                                    <span class="d-flex align-items-center">


                                      <feather-icon
                                    icon="TwitterIcon"
                                    size="18"
                                    class="color_redes_link"
                                    />

                                    </span>

                                    </b-avatar>
                                  
                                    </a>
                                
                            </b-col>
                            <b-col cols="3" sm="3" md="3" xl="3" lg="3" v-if="instagram" style="text-align: center; padding: 0px;">

                            <a class="icon_dashboard" :href="instagram" target="blank"> 

                                <b-avatar
                                size="35"
                                variant="light-primary"
                                style="cursor:pointer;">

                                <span class="d-flex align-items-center">


                                  <feather-icon
                                icon="InstagramIcon"
                                size="18"
                                class="color_redes_link"
                                />

                                </span>

                                </b-avatar>
                                
                            </a>
                                  
                            </b-col>


                            <b-col cols="3" sm="3" md="3" xl="3" lg="3" v-if="tiktok" style="text-align: center;padding: 0px ">

                                <a class="icon_dashboard" :href="tiktok" target="blank"> 

                                    <b-avatar
                                    size="35"
                                    variant="light-primary"
                                    style="cursor:pointer;">

                                    <span class="d-flex align-items-center">


                                      <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#000"
                                            widht="10"
                                            height="16"
                                            viewBox="0 0 448 512"
                                          ><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg>

                                    </span>

                                    </b-avatar>
                                 
                                </a>
                                      
                                </b-col>


      
                    </b-row>
                  </b-col>

                  </b-row>


            </b-col>





   
      </b-row>

 

      </b-card>



      </b-col>

      <b-col cols="12" sm="12" md="12" xl="4" lg="7" v-if="loading" class="sin_padding2">
    
        <b-row>

   


                  <b-col sm="12" md="12" lg="12" xl="12" class="card3"> 

                    <b-row>

              

                        <b-col cols="4" sm="4" md="4" lg="4" xl="4"> 

                          <b-skeleton  width="100%" height="30px" style="    margin-top: 7px;" ></b-skeleton>


                          </b-col>

                          <b-col cols="4" sm="4" md="4" lg="4" xl="4"> 
                            <b-skeleton  width="100%" height="30px" style="    margin-top: 7px;" ></b-skeleton>


                          </b-col>

                          <b-col cols="4" sm="4" md="4" lg="4" xl="4"> 
                            <b-skeleton  width="100%" height="30px" style="    margin-top: 7px;" ></b-skeleton>


                          </b-col>

                    </b-row>

                  </b-col>


                  </b-row>

                  <b-card>
                      <b-row>

                          
                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6"></b-col>

                <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <b-skeleton width="100%"  height="40px"></b-skeleton>

                </b-col>

                <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <b-skeleton width="100%"  height="40px"></b-skeleton>

                </b-col>

                <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>


                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="40px"></b-skeleton>

                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="40px"></b-skeleton>

                  </b-col>


                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  
                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <b-skeleton width="100%"  height="40px"></b-skeleton>

                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  
                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <b-skeleton width="100%"  height="40px"></b-skeleton>

                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>
                  </b-col>

                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  
                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <b-skeleton width="100%"  height="40px"></b-skeleton>

                  </b-col>


                  <b-col cols="8" sm="8" md="8" lg="8" xl="8">
              
                  </b-col>

                  <b-col cols="4" sm="4" md="4" lg="4" xl="4">
                    <b-skeleton width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>

                  </b-col>


                            </b-row>

                   


                    </b-card>


      </b-col>


      <b-col cols="12" sm="12" md="12" xl="4" lg="7" v-else class="sin_padding2">

     
        <b-row>



                <b-tabs
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1 mt-md-0 sin_padding"
                pills
                fill  
                nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 card3"
                style="    padding: 0;"
        

                >

                <b-tab  @click="payTarjetas">

                                  
                <template #title >
                  <img class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block" style="width: 25px; margin-right: 5px" src="/img/visa2.svg"/>
                    <span class="texto">Tarjetas</span>



                </template>

                <b-card>

                  <b-row >



                    <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px" >
                  
                    <tarjetas  :key="componentKeyTarjetas" :alias="alias" :token-auth="tokenAuth"   />

                  </b-col>


                  </b-row>


                </b-card>




                </b-tab>
             

                <b-tab @click="payEQCoop"  >


                  <template #title>

                  <span class="texto">Saldo EQCoop</span>


                    </template>



                        <b-card>

                        <b-row >



                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px" >

                            <saldo v-if="loadSaldo" :key="componentKeySaldo" :is-logeo="isLogeo" :alias="alias" :photo="photo" :display-name="displayName"  />

                          </b-col>


                        </b-row>


                      </b-card>



                </b-tab>

           

                    <b-tab @click="payWester" >

                        <template #title>
                         <span class="texto">Red Activa / Wester Unión</span>
               

                        </template>


                  
                          <wester-form v-if="loadWester" :alias="alias" :key="componentKeyWesterForm" :is-logeo="isLogeo" :token-auth="tokenAuth" />




                   

                    


                        </b-tab>




                </b-tabs>


                </b-row>


      </b-col>

      <b-col cols="12" sm="12" md="12" xl="4" lg="12" class="sin_padding2">

        
        <b-row v-if="!isMobile" class="d-none d-sm-none d-md-none d-lg-none d-xl-block">

 
                  <b-col v-if="loading" sm="12" md="12" lg="12" xl="12" class="card3"> 
                
                    <b-row>

                      <b-col cols="3" sm="3" md="3" lg="3" xl="3"> 

                

                        </b-col>

                        <b-col cols="3" sm="3" md="3" lg="3" xl="3"> 

                          <b-skeleton  width="100%" height="30px" style="    margin-top: 7px;" ></b-skeleton>


                          </b-col>

                          <b-col cols="3" sm="3" md="3" lg="3" xl="3"> 

                            <b-skeleton  width="100%" height="30px" style="    margin-top: 7px;" ></b-skeleton>


                          </b-col>

                          <b-col cols="3" sm="3" md="3" lg="3" xl="3"> 

                      
                          </b-col>

                    </b-row>

                  </b-col>
              
                    <b-card v-if="loading" cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px;width: 100%;" >

                      <b-row>
                        <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px" > 

                          <b-skeleton  width="100%" height="30px" style="    margin-top: 7px;" ></b-skeleton>
                        </b-col>

                        <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="padding:0px" > 

                          </b-col>

                          <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="padding:0px" > 

                            <b-skeleton  width="100%" height="80px"  ></b-skeleton>

                          </b-col>

                          <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="padding:0px" > 

                          </b-col>
                      </b-row>


                    </b-card>
          
                   



                <wallets v-else :alias="alias" :address-u="addressU" :photo="photo" :id-wallet-u="idWalletU" />


              </b-row>

              <b-row>
                <b-col
                      style="color:black !important;margin-bottom: 25px;"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      class="text-center align-items-center justify-content-xl-between"
                    >
                
                  <hr>

                      <a
                        target="_blank"
                        title="Facebook de EQCoop"
                        href="https://www.facebook.com/eqpay.oficial"
                      >   <feather-icon
                      icon="FacebookIcon"
                      size="21"
                      class="redes"
                      /></a>
                      <a
                        target="_blank"
                        title="Instagram de EQCoop"
                        href="https://www.instagram.com/eqpay.oficial"
                      > <feather-icon
                      icon="InstagramIcon"
                      size="21"
                      class="redes"
                      /></a>
                      <a
                        target="_blank"
                        title="Email de EQCoop"
                        href="mailto:hola@eqcoops.com?Subject=Tengo%20una%20pregunta"
                      > <feather-icon
                      icon="MailIcon"
                      size="21"
                      class="redes"
                      /></a>
                      <a
                        target="_blank"
                        title="Tiktok de EQCoop"
                        href="https://www.tiktok.com/@eqpays?_t=8WRpV7M3v65&_r=1"
                      > <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#00838C"
                        widht="10"
                        height="18"
                        viewBox="0 0 448 512"
                      ><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg></a>

                      <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;">  <a
                        href="#"
                        target="_blank"
                      >Términos y condiciones</a> - <b-link
                        :to="{ name: 'privacidad'}"
                        target="_blank"
                      >Política de privacidad y condiciones de servicios</b-link> -  <a
                        href="#"
                        target="_blank"
                      >Reclamos</a></p>

                      <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> Copyright &copy; {{ year }} <a
                        href="https://web.eqcoops.com"
                        target="_blank"
                      >- EQCoop</a></p>

                    </b-col>
              </b-row>    


      </b-col>

      
    
    
    </b-row>

    <b-row v-else>

      <not-alias title="Usuario no encontrado" message="No encontramos a un usuario con el alias proporcionado." />

      </b-row>
    
  </div>
    
    </template>
    
    <script>
    import {
      BButton,VBPopover,BDropdown,BDropdownItem, BRow, BCol, BCard,BTabs, BTab,BAlert, BSkeleton,BSkeletonImg, BAvatar, BLink,BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,
    } from 'bootstrap-vue'

    import AppCollapse from '@core/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/app-collapse/AppCollapseItem.vue'

    import VueQr from 'vue-qr'
    import Login from './Login.vue'
    import Saldo from './Saldo.vue'
    import WesterForm from './WesterForm.vue'
    import NotAlias from './NotAlias.vue'
    import Wallets from './Wallets.vue'
    import FormPayCripto from './FormPayCripto.vue'
    import Tarjetas from './Tarjetas.vue'
    import firebase from 'firebase/compat/app'
        import 'firebase/compat/auth'
        import 'firebase/compat/app-check'


    export default {
      components: {
        BButton,
        VueQr,
        WesterForm,
        Wallets,
        FormPayCripto,
        AppCollapse,
        AppCollapseItem,
        NotAlias,
        BDropdownItem,
        BAlert,
        Saldo,
        BDropdown,
        BSkeletonImg,
        BLink,
        BSkeleton,
        BAvatar,
        BRow,
        BCol,
        BCard,
        Tarjetas,
        BTabs, BTab,
        Login,
        BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup
      },
        directives: {
        'b-popover': VBPopover,
      },
      data() {
        document.body.classList.remove('dark-layout')
        let alias=this.$route.params.alias;

        let isLogeo=false;
          
          let tokenAuth="";

          const user = localStorage.getItem('userData');


          if(user){

            const datos_json = JSON.parse(user);

            let t=datos_json.t;


            tokenAuth = datos_json.tokenAuth;
            if (typeof tokenAuth === "undefined" || tokenAuth === "" || typeof t === "undefined" || t === "") {

              localStorage.removeItem('userData')
          
                
            }else{

              const is_incorrect = isNaN(t)
                  
                  if (is_incorrect) {
                    
                    localStorage.removeItem('userData')
                 
                  }else{

                    const currentDate = new Date().getTime()
                      if (Number(currentDate) > Number(t)) {

                        
                        localStorage.removeItem('userData')
                     

                      }else{
                         isLogeo=true;
                      }


                  }

          

              
            

              
            }
          
        
            
            }
    
        return {
          appCheck: null,
            banner: "/img/banner.svg",
            photo: "",
            tokenAuth:tokenAuth,
            loading:true,
            componentKeyTarjetas:0,
            loadSaldo:false,
            isLogeo:isLogeo,
            isMobile:this.$isMobile,
            loadingCriptos:true,
            displayName:"",
            alias:alias,
            addressUSDT:"",
            addressQR2:"",
            address:"",
            currency:"",
            year: new Date().getFullYear(),
            logoScale:0.3,
            logoMargin: 1,
            margin:50,
            logoCornerRadius:100,
            hideQR:true,
            existeAlias:true,
            biografia:"",
            isProfileVerificated:false,
            registro:"",
            ubicacion:"",
            facebook:"",
            twitter:"",
            instagram:"",
            tiktok:"",
            moreText:false,
            isLogin:false,
            componentKeySaldo:100,
            componentKeyBilletera:200,
            correctLevel:3,
            componentKeyWesterForm:300,
            componentKeyPayCripto:400,
            size:800,
            dataUrlCripto:"",
            wallets:[],
            loadWallets:true,
            showCriptos:true,
            loadWester:false,
            titleCripto:"Seleccione una criptomoneda",
            logoCripto:"",
            addressU:"",
            idWalletU:"",
            idWallet:"",
            showQRDiv:false,

        }
      },
      computed: {
    
      },
      watch: {



    
      },
       created(){

       


              document.title = 'EQCoop - Billetera de @' + this.alias;



      },
      async  mounted() {

          
        
        if(!this.isLogeo){

            this.appCheck = firebase.appCheck()


            this.appCheck.activate(
              '6LdazQMpAAAAAONOLS6DmRFWQJrCE-nzNbPXJOTG',

              // Optional argument. If true, the SDK automatically refreshes App Check
              // tokens as needed.
              true,
            )
            this.appCheck.setTokenAutoRefreshEnabled(true) 

                
            let tokenAux= await this.appCheck.getToken();

            this.tokenAuth= tokenAux.token;


            this.getInfo();


            }else{
              this.getInfo();
            }


    this.$eventBus.$on('hideCriptos', () => {
      this.hideCriptos();
    })

    
    this.$eventBus.$on('reiniciarWesterForm', () => {
        this.componentKeyWesterForm += 1
      });

      
      this.$eventBus.$on('reiniciarPayTarjetas', () => {
        this.componentKeyTarjetas += 1
      })


      this.$eventBus.$on('reiniciarSaldoEQCoop', () => {
        this.componentKeySaldo += 1
      })

      this.$eventBus.$on('reiniciarPayCripto', () => {
        this.componentKeyPayCripto += 1
      })
       
      this.$eventBus.$on('reiniciarAllFormsPay', () => {
        this.componentKeyBilletera += 1;
       
      })




   
            window.onscroll = function() {
              var scroll = window.pageYOffset;
            
              if (scroll >= 30) {

                
                document.getElementById("logoEQ").classList.remove('logoSVG2')
                document.getElementById("logoEQ").classList.add('eq_color')
                document.getElementById("menu_fixed").classList.remove('sin_background')
         
                
              } else {

                document.getElementById("logoEQ").classList.remove('eq_color')
                document.getElementById("logoEQ").classList.add('logoSVG2')

        
                document.getElementById("menu_fixed").classList.add('sin_background')
             
                  
              }
         
            }
                
       
      },
      methods: {
        payTarjetas(){
      this.hideCriptos();
    },
    closeModal(){
      this.$refs.modalPaySuccess.hide();
    },

    onChangeCripto(currency, address,idWallet){
      this.currency= currency;
      this.address= address;
      this.idWallet=idWallet;
      this.addressQR2=idWallet+";billetera";

      let currencyRespaldo="";

      if(this.currency === "BSC"){
        currencyRespaldo="BNB"

      }else{
        currencyRespaldo= this.currency
      }

      this.titleCripto="Enviar saldo "+ currencyRespaldo;

      switch(this.currency) {

                case "USDT":
                this.logoCripto= "/img/usdt.svg"
      
           
                case "BSC":

                this.logoCripto= "/img/bsc.svg"
      
              break;

    }

      this.showCriptos=false;
      this.$eventBus.$emit('reiniciarPayCripto');
      

  
    },

    hideCriptos(){

      this.showCriptos=true;
      this.titleCripto="Seleccione una criptomoneda";
      this.$eventBus.$emit('clearInterval');

    },






    getDataURLCripto(dataUrl,id){
      this.dataUrlCripto= dataUrl;
      this.hideQR=false;
    },

  descargarCripto(){
    
    var link = document.createElement("a");
    link.download = "Billetera " + this.currency + " de " +  this.alias;
    link.href =this.dataUrlCripto;
    link.click();
     
     

  },
  closeModalQR(){


this.$refs.modalShowQR.hide();
},

    onCopyAddressUSDT(copy) {

      

      if (this.isMobile) {


        if (navigator.share) {
            navigator.share({
              title: 'EQCoop - Billetera de ' + this.displayName,
              text: this.displayName + ' solicita un pago.',
              url: window.location.href,
            })
              .then(() => console.log('Successful share'))
              .catch((error) => console.log('Error sharing', error));
          }

} else {


// Copy the text inside the text field
navigator.clipboard.writeText(copy);

this.$toast.success('Address copiado exitosamente', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })

}




    },


   

    sharedCripto(){
    
    
    fetch(this.dataUrlCripto).then(function (response) {
      return response.blob()
    }).then(function (blob) {
      let file = new File([blob], "Address.jpg", {type: 'image/jpeg'});
      let filesArray = [file];
      if (navigator.canShare && navigator.canShare({files: filesArray})) {
        navigator.share({
          files: filesArray
        }).then(() => console.log('Successful share'))
          .catch((error) =>   this.$toast.error("Ocurrió un error inesperado", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          }));
      }else{

          this.$toast.error("Lo sentimos, tu navegador no es compatible para compartir este contenido", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

      }
    });


  

  },

  showQR(){

    this.showQRDiv=true;

this.$refs.modalShowQR.show();

},
    shared(){

      if (navigator.share) {
            navigator.share({
              title: 'EQCoop - Billetera de ' + this.displayName,
              text: this.displayName + ' solicita un pago.',
              url: window.location.href,
            })
              .then(() => console.log('Successful share'))
              .catch((error) => console.log('Error sharing', error));
          }

    },


    getWalletsByAlias(){

      if(this.loadWallets){

      
      this.loadingCriptos=true;
     


      this.$httpsCryptos.post('/main/getWalletsByAlias/', { alias: this.alias }).then(response => {
        

      if (response.data.code == 200) {
      
          this.wallets= response.data.wallets;

          
          this.loadWallets=false;
          this.loadingCriptos=false;
  
      } else {

      



          if (response.data.code == 404) {

            this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

            this.existeAlias=false;

          }else{ 
            this.getWalletsByAlias();

          }
          
        
      }
    }).catch(error => {
         this.getWalletsByAlias();
    })

  }
    },

    payEQCoop(){

      this.hideCriptos();

      this.loadSaldo=true;
    },

    payWester(){

      this.hideCriptos();

      this.loadWester=true;

      },


        getInfo(){
      this.loading=true;
     

    this.$https.post('/links/getUserByAlias/', { alias: this.alias }).then(response => {
        

       
      if (response.data.code == 200) {
      
  

          this.banner=response.data.banner;
          this.registro=response.data.registro;



          this.ubicacion=response.data.ubicacion;

          this.photo=response.data.photo;
          this.idWalletU=response.data.idWalletUSDT;
          this.addressUSDT=response.data.addressUSDT;
          this.addressU=this.addressUSDT;
          this.biografia=response.data.biografia;
          this.displayName= response.data.displayName.toLowerCase();
          this.isProfileVerificated=response.data.isProfileVerificated;

          if(response.data.facebook != ""){
            this.facebook="https://www.facebook.com/"+response.data.facebook;
          }

          if(response.data.twitter != ""){
            this.twitter="https://twitter.com/"+response.data.twitter;
          }

          if(response.data.instagram != ""){
            this.instagram="https://www.instagram.com/"+response.data.instagram;
          }


          if(response.data.tiktok != ""){
            this.tiktok="https://www.tiktok.com/"+response.data.tiktok;
          }

          this.loading = false;

      } else {




          if (response.data.code == 404) {

            this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })


            this.existeAlias=false;

          }else{ 
            this.getInfo();

          }
          
        
      }
    }).catch(error => {
         this.getInfo();
    })
    },

   
    
    
    
      },
    }
    </script>
    
    <style lang="scss">




        
    </style>
    